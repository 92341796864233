<div class="di-toolbar" fxFlex>
    <!-- <div fxFlex fxFill fxLayout="row" fxLayoutAlign="end center">
            <h1>{{companyName}}</h1>
        </div>

        <div fxFlex fxFill fxLayout="row" fxLayoutAlign="end center"> -->

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <div class="px-12" *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <fa-icon [icon]="faMenu"></fa-icon>
            </div>
        </div>

        <ai-input placeholderText="Let AI Help You...." commandMode="true"></ai-input>

        <div fxFlex="1 0 auto" (click)="resetSwCacheBtn()" style="cursor: pointer" *ngIf="cacheEnabled">
            <fa-icon id="refresh" [icon]="faSyncAlt" class="mr-8" nz-tooltip nzTooltipTitle="Reset Cache"></fa-icon>
        </div>

        <div class="mr-16"></div>

        <div *ngIf="!isSuperuser">
            <span *ngIf="logoSrc == null">{{ companyName }}</span>
            <div style="width: 64px">
                <img
                    *ngIf="logoSrc !== null"
                    [height]="logoHeight"
                    [width]="logoWidth"
                    class="image"
                    [src]="_domSanitizer.bypassSecurityTrustUrl(logoSrc)"
                    alt="Company Logo" />
            </div>
        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <!-- <div class="px-24 py-16">
                <a nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <img class="avatar mr-0 mr-sm-16" src="assets/images/avatars/profile.jpg" />
                        <span class="username mr-12" fxHide fxShow.gt-sm>{{ name }}</span>
                        <fa-icon fxHide.xs [icon]="faAngleDown"></fa-icon>
                    </div>
                </a>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu>
                        <a
                            nz-menu-item
                            style="text-decoration: none; display: block; width: 100%"
                            target="_blank"
                            href="https://datainsightshelp.freshdesk.com/support/home">
                            <fa-icon [icon]="faQuestionCircle" class="mr-8"></fa-icon>
                            <span>Help</span>
                        </a>
                        <li id="logout" nz-menu-item style="text-decoration: none" (click)="logout()">
                            <fa-icon [icon]="faSignOutAlt" class="mr-8"></fa-icon>
                            <span>Logout</span>
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </div> -->

            <!-- <nz-divider nzType="vertical"></nz-divider> -->

            <div *ngIf="bulkProgress$ ? bulkProgress$.value.length > 0 : false" [satPopoverAnchor]="bulkProgress">
                <span (click)="bulkProgress.toggle()">
                    <nz-badge [nzCount]="(bulkProgress$ | async).length" nzSize="small">
                        <i>
                            <mat-icon>hourglass_empty</mat-icon>
                        </i>
                    </nz-badge>
                </span>
            </div>

            <!-- <nz-divider nzType="vertical"></nz-divider> -->

            <div class="px-24 py-12">
                <a nz-dropdown [nzDropdownMenu]="langMenu">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <img class="flag mr-8" [src]="'assets/images/flags/' + selectedLanguage?.flag + '.png'" />
                        <span class="iso text-uppercase">{{ selectedLanguage?.id }}</span>
                    </div>
                </a>
                <nz-dropdown-menu #langMenu="nzDropdownMenu">
                    <ul nz-menu nzSelectable>
                        <li nz-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang, true)">
                            <span fxLayout="row" fxLayoutAlign="start center">
                                <img class="flag mr-16" [src]="'assets/images/flags/' + lang.flag + '.png'" />
                                <span class="iso">{{ lang.title }}</span>
                            </span>
                        </li>
                    </ul>
                </nz-dropdown-menu>
                <!-- <div>v{{ appVersionNo }}</div> -->
            </div>
        </div>
    </div>
</div>
<div class="app-version">v{{ appVersionNo }}</div>
<sat-popover horizontalAlign="end" verticalAlign="below" #bulkProgress hasBackdrop>
    <div class="bordered-popover">
        <div class="popover-title" style="margin: 5px">
            <span><b class="title">Background Tasks</b></span>
        </div>

        <div style="max-height: 150px; overflow: auto">
            <perfect-scrollbar>
                <div style="margin: 20px" *ngFor="let bulkProgress of bulkProgress$ | async" class="notification">
                    <div style="min-width: 300px">
                        <b>{{ bulkProgress.operationName }}</b>
                    </div>
                    <div>Task ID:{{ bulkProgress.id }}</div>
                    <div>
                        <span style="padding-right: 5px">Succeded:{{ bulkProgress.successCount }}</span
                        ><span>Failed:{{ bulkProgress.failureCount }}</span>
                    </div>
                    <nz-progress
                        [nzPercent]="
                            ((bulkProgress.successCount + bulkProgress.failureCount) * 100.0) / bulkProgress.totalCount | roundTotal
                        "></nz-progress>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
</sat-popover>
