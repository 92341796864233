import {ClientPortalRouteMagicAssistantModule} from 'app/layout/components/routemagic-assistant/client-portal-routemagic-assistant/client-portal-routemagic-assistant.module';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';

import {FuseSharedModule} from '@fuse/shared.module';
import {RouteMagicAssistantComponent} from 'app/layout/components/routemagic-assistant/routemagic-assistant.component';

@NgModule({
    declarations: [RouteMagicAssistantComponent],
    imports: [RouterModule, MatButtonModule, MatIconModule, MatToolbarModule, FuseSharedModule, ClientPortalRouteMagicAssistantModule],
    exports: [RouteMagicAssistantComponent]
})
export class RouteMagicAssistantModule {}
