import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {Router} from '@angular/router';
import {AccountService} from 'app/core/auth/account.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ForgotPasswordComponent implements OnInit {
    forgotPasswordForm: UntypedFormGroup;

    /**
     * Constructor
     *
     * @param {Router} router
     * @param _toast
     * @param accountService
     * @param {UntypedFormBuilder} _formBuilder
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private router: Router,
        private _toast: ToastrService,
        private accountService: AccountService,
        private _formBuilder: UntypedFormBuilder,
        private _fuseConfigService: FuseConfigService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.forgotPasswordForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            tenant: ['', [Validators.required]]
        });
    }

    /**
     * Send activation link to user's email
     */
    // tslint:disable-next-line:typedef
    sendActivationLink() {
        this.accountService.requestPasswordReset(this.forgotPasswordForm.value.email, this.forgotPasswordForm.value.tenant).subscribe(
            (result) => {
                console.log(result);
                this._toast.info('A password reset link has been mailed to your email address');
            },
            (err) => {
                console.log(err);
                if (err.error.title === 'Email address not registered') {
                    this._toast.error('Email address not registered');
                } else {
                    this._toast.error('Invalid Client ID.');
                }
            }
        );
        this.router.navigate(['/login']);
    }
}
