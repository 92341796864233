import {instanceToPlain, plainToInstance} from 'class-transformer';

export class StorageSerializer {
    static serializeWithType(obj: any): string {
        return JSON.stringify(instanceToPlain(obj));
    }

    static deserializeWithType(json: string): any {
        const parsedData = JSON.parse(json);
        return plainToInstance(parsedData.type, parsedData.data);
    }
}
