export const locale = {
    lang: 'en',
    data: {
        error: {
            validation: 'Validation error',
            userexists: 'Employee already exists'
        },
        common: {
            reindex: {
                reindex: 'Re-Index',
                messages: {
                    ResultOK: {
                        title: '',
                        message: 'Table Reindexed successfully'
                    },
                    ResultFailed: {
                        title: '',
                        message: 'Table Reindexed Failed!'
                    }
                }
            },
            search: 'Search',
            confirmation: 'Confirmation',
            yes: 'Yes',
            no: 'No',
            error: 'Error',
            sorry: 'Sorry',
            ok: 'OK',
            cancel: 'Cancel',
            save: 'Save',
            export: 'Export All',
            deactivate: 'Deactivate',
            update: 'Update',
            errorMessage: 'Requested operation failed. Please contact the administrator.',
            deleteFailedMessage: 'This entity cannot be deleted as it is referenced by other entities.',
            gatewayTimeoutErrorMessage: 'The server did not respond within the expected time frame. Please try again later.',
            exportGatewayTimeoutErrorMessage: 'The export operation is taking longer than usual. Please try exporting few or in batches.',
            importGatewayTimeoutErrorMessage: 'The import operation is taking longer than usual. Please try importing in batches.',
            confirmDelete: {
                message: 'Are you sure you want to delete {{ discriminator }}?'
            },
            routePlannerFailedOptimization: {
                message: 'Optimization failed, please check that your route / branch have starting coordinates.'
            },
            forms: {
                validationMessages: {
                    required: {
                        default: '{{_fieldName}} is required'
                    },
                    floatRange: {
                        default: '{{_fieldName}} should be between {{min}} and {{max}}'
                    },
                    maxlength: {
                        default: '{{_fieldName}} can have a maximum of {{requiredLength}} characters'
                    },
                    minlength: {
                        default: '{{_fieldName}} should have a minimum of {{requiredLength}} characters'
                    },
                    min: {
                        default: '{{_fieldName}} should be more than or equal to {{min}}'
                    },
                    max: {
                        default: '{{_fieldName}} should be at most {{max}}'
                    },
                    formValid: {
                        default: 'Please fill in all required fields with valid values before attempting to save',
                        defaultWithSectionHighlighted:
                            'Please fill in all required fields with valid values before attempting to save. Sections containing missing values have been highlighted in red'
                    },
                    confirmPassword: {
                        default: 'Confirm password and password not equal'
                    },
                    email: {
                        default: 'Invalid email'
                    },
                    timeRange: {
                        default: 'Invalid time window'
                    },
                    maxWeight: {
                        default: 'Invalid weight'
                    },
                    pattern: {
                        default: '{{_fieldName}} is not valid'
                    },
                    duplicateProductLine: {
                        default: 'Duplicate product line'
                    },
                    invalidPurchaseQuantity: {
                        default: 'Quantity should be a multiple of {{val}}'
                    },
                    greaterThan: {
                        default: '{{_fieldName}} should be greater than {{val}}'
                    },
                    lessThan: {
                        default: '{{_fieldName}} should be less than {{val}}'
                    },
                    insufficientStock: {
                        default: 'Insufficient stock'
                    },
                    duplicateWarehouseBinLocations: {
                        default: 'Duplicate Warehouse Bin locations'
                    }
                },
                unsavedChangesWarning: {
                    default: 'You have unsaved changes, are you sure you want to continue?'
                }
            },
            selection: {
                selectAll: 'All',
                selectNone: 'None'
            },
            genderWords: {
                m: {
                    _a: '',
                    _the: ''
                },
                f: {
                    _a: '',
                    _the: ''
                }
            }
        }
    }
};
