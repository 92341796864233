import {tap} from 'rxjs/operators';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

import {Injectable} from '@angular/core';
import {DialogService} from 'app/common/dialog/dialog-service';
import {HttpStatusCode} from 'app/constants/http-status-codes';
import {REGEX_GOOGLE_SHEET_URL} from 'app/app.constants';

const API_ENDPOINTS_IGNORE_NOT_FOUND_ERROR = ['/api/company', '/api/employees'];

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
    constructor(private dialogService: DialogService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => {},
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (
                            !(
                                err.status === HttpStatusCode.UNAUTHORIZED ||
                                err.message === '' ||
                                (err.url &&
                                    ((err.url.indexOf('/api/account') > 0 && err.url.indexOf('/api/accounting-packages/') < 0) ||
                                        err.url.indexOf('/api/company/logo') > 0)) ||
                                REGEX_GOOGLE_SHEET_URL.test(err.url)
                            )
                        ) {
                            console.log('[ErrorHandlerInterceptor] There was an http error: ', err);
                            console.log('URL', err.url);
                            let message = 'common.errorMessage';
                            let title = 'common.error';
                            if (request.method === 'DELETE') {
                                message = 'common.deleteFailedMessage';
                                title = 'common.sorry';
                            } else if (
                                err.url &&
                                API_ENDPOINTS_IGNORE_NOT_FOUND_ERROR.some((endpoint) => err.url.includes(endpoint)) &&
                                err.status === HttpStatusCode.NOT_FOUND
                            ) {
                                return;
                            }
                            if (err.status === HttpStatusCode.BAD_REQUEST && !!err.error && !!err.error.title) {
                                title = err.error.title;
                            }
                            if (err.status === HttpStatusCode.BAD_REQUEST && !!err.error && !!err.error.detail) {
                                message = err.error.detail;
                            }
                            // special case for graphhopper error
                            if (
                                err.status === HttpStatusCode.BAD_REQUEST &&
                                err.error != null &&
                                err.error.params != null &&
                                err.error.params.graphhopperMessage != null
                            ) {
                                message = this.getErrorMessageForGrasshopperFromParams(err.error.params.graphhopperMessage);
                            }
                            if (err.status === HttpStatusCode.DUPLICATE_ENTITY && !!err.error.detail) {
                                title = 'Duplicate entry';
                                message = this.formatErrorMessage(err.error.detail);
                            }
                            if (err.status === HttpStatusCode.INTERNAL_SERVER_ERROR && !!err.error.detail) {
                                message = err.error.detail;
                            }
                            if (err.status === HttpStatusCode.GATEWAY_TIMEOUT) {
                                title = 'Gateway Timeout';
                                message = 'common.gatewayTimeoutErrorMessage:';

                                // Check if the error URL contains '/export/' to determine if the timeout occurred during an export operation
                                if (err.url && /\/export\//i.test(err.url)) {
                                    title = 'Export Timeout';
                                    message = 'common.exportGatewayTimeoutErrorMessage';
                                }

                                // Check if the error URL contains '/import/' to determine if the timeout occurred during an import operation
                                if (err.url && /\/import\//i.test(err.url)) {
                                    title = 'Import Timeout';
                                    message = 'common.importGatewayTimeoutErrorMessage';
                                }
                            }

                            /* if (err.url && err.url.includes('route-optimization') && err.status === 400) {
                            message = 'common.routePlannerFailedOptimization.message';
                        } */

                            this.dialogService.showMessage({
                                message: message,
                                title: title,
                                confirmText: 'common.ok'
                            });
                        }
                    }
                }
            )
        );
    }

    formatErrorMessage(message: string): string {
        // {message format}: Duplicate entry 'xyz' for key 'employee.employee_id/product.code/purchase_invoice_summary.invoice_no'
        // ---converted to---> Duplicate entry 'xyz' for field 'id/code/invoice no'
        let finalMessage = message.replace('key', 'field');
        finalMessage = finalMessage.replace(/'[^.']+\.([^']+)'/g, "'$1'");
        finalMessage = finalMessage.replace(/'[^']+'\./g, "'");
        return finalMessage.replace('_', ' ');
    }

    getErrorMessageForGrasshopperFromParams(errorMessage: any): any {
        return this.extractErrorReasons(errorMessage).join(', ');
    }

    extractErrorReasons(errorMessage) {
        try {
            // Find the start of the JSON part
            const jsonStart = errorMessage.indexOf('{');
            if (jsonStart === -1) {
                // No valid JSON found
                return [];
            }

            // Extract the JSON part
            const jsonPart = errorMessage.substring(jsonStart);

            // Parse the JSON
            const parsedError = JSON.parse(jsonPart);
            const errorReasons = [];

            if (parsedError.hints && Array.isArray(parsedError.hints)) {
                parsedError.hints.forEach((hint) => {
                    if (hint.message) {
                        errorReasons.push(hint.message);
                    }
                });
            }

            return errorReasons;
        } catch (error) {
            console.error('Error parsing the error message:', error);
            return [];
        }
    }
}
