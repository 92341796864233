import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {MfaService} from 'app/blocks/service/api/mfa.service';
import {NzModalRef} from 'ng-zorro-antd/modal';

@Component({
    selector: 'mfa',
    templateUrl: './mfa.component.html',
    styleUrls: ['./mfa.component.scss'],
    animations: fuseAnimations
})
export class MfaComponent implements OnInit, OnDestroy {
    mfaForm: UntypedFormGroup;

    // Private
    private _unsubscribeAll: Subject<void>;
    public isMfaVerified = true;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {UntypedFormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: UntypedFormBuilder,
        private mfaService: MfaService,
        public modal: NzModalRef
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.mfaForm = this._formBuilder.group({
            mfaCode: ['', [Validators.required]]
        });
    }

    verify(e): void {
        const mfaCode = this.mfaForm.get('mfaCode').value;
        if (mfaCode != null) {
            this.mfaService.isMfaVerified(mfaCode).then((result) => {
                this.isMfaVerified = result.body.isMfaVerified;
                if (this.isMfaVerified) {
                    this.modal.close(this.isMfaVerified);
                }
            });
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On form values changed
     */
}
