import {Component, OnInit} from '@angular/core';

import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthServerProvider} from 'app/core/auth/auth-jwt.service';
import {AlertService} from 'app/common/alert/alert.service';

@Component({
    selector: 'redirect-handler',
    templateUrl: './redirect-handler.component.html',
    animations: fuseAnimations
})
export class RedirectHandlerComponent implements OnInit {
    readonly redirectTypeHandlers: {[key: string]: (params: Params) => void} = {
        'auth-success': this.onAuthSuccessRedirect.bind(this),
        error: this.onErrorRedirect.bind(this)
    };

    constructor(
        private _fuseConfigService: FuseConfigService,
        private route: ActivatedRoute,
        private router: Router,
        private authJwtService: AuthServerProvider,
        private alertService: AlertService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            const type = params['type'];
            if (!type) {
                this.onError('No type parameter provided');
            }
            const handler = this.redirectTypeHandlers[type];
            if (!handler) {
                this.onError('No handler defined for type ' + type);
            }
            handler(params);
        });
    }

    onAuthSuccessRedirect(params: Params): void {
        const token = params.token;
        if (!token) {
            this.onError('No token parameter provided');
        }
        this.authJwtService.storeAuthenticationToken(token, true);
        this.router.navigate(['/']);
    }

    onErrorRedirect(params: Params): void {
        const message = params.message;
        if (!message) {
            this.onError('Unknown error');
        }
        this.onError(message);
    }

    onError(error: string): void {
        this.alertService.showError(error);
        this.router.navigate(['/']);
    }
}
