import {ErrorHandler, Injectable} from '@angular/core';
import {BrowserAgent} from '@newrelic/browser-agent/loaders/browser-agent';

@Injectable({
    providedIn: 'root'
})
export class GlobalErrorInterceptor implements ErrorHandler {
    constructor(private newrelicAgent?: BrowserAgent) {}
    handleError(error: any): void {
        this.newrelicAgent?.noticeError(error);
        throw error;
    }
}
