import {Component, OnDestroy, OnInit} from '@angular/core';
import {ROUTEMAGIC_ASSISTANT_ENABLED} from 'app/app.constants';
import {RmAuthorities} from 'app/blocks/enum/rm-authorities.enum';
import {Principal} from 'app/core/auth/principal.service';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {Subscription} from 'rxjs';

export enum AssistantType {
    CUSTOMER // Used for client portal
}

@Component({
    selector: 'routemagic-assistant',
    templateUrl: './routemagic-assistant.component.html',
    styleUrls: ['./routemagic-assistant.component.scss']
})
export class RouteMagicAssistantComponent implements OnInit, OnDestroy {
    authStateSubscription: Subscription;
    activeAssistantType?: AssistantType = null;

    constructor(private _principal: Principal) {
        this.authStateSubscription = this._principal.getAuthenticationState().subscribe((userIdentity) => {
            if (!userIdentity) {
                return;
            }
            if (Principal.userIdentityHasAuthority(userIdentity, RmAuthorities.ROLE_CUSTOMER)) {
                this.activeAssistantType = AssistantType.CUSTOMER;
            }
        });
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.authStateSubscription.unsubscribe();
    }

    public isAssistantEnabled(): boolean {
        return ROUTEMAGIC_ASSISTANT_ENABLED;
    }

    public get assistantType(): typeof AssistantType {
        return AssistantType;
    }
}
