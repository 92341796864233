import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';

import {FuseSearchBarModule, FuseShortcutsModule} from '@fuse/components';
import {FuseSharedModule} from '@fuse/shared.module';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {ToolbarComponent} from 'app/layout/components/toolbar/toolbar.component';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {SatPopoverModule} from '@ncstate/sat-popover';
import {PerfectScrollbarModule} from 'perfect-scrollbar-angular';
import {NzProgressModule} from 'ng-zorro-antd/progress';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {AIInputModule} from 'app/common/ai-input/ai-input.module';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';

@NgModule({
    declarations: [ToolbarComponent],
    imports: [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        SatPopoverModule,
        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        NzDropDownModule,
        NzButtonModule,
        NzDividerModule,
        FontAwesomeModule,
        PerfectScrollbarModule,
        NzProgressModule,
        NzBadgeModule,
        AIInputModule,
        NzToolTipModule
    ],
    exports: [ToolbarComponent]
})
export class ToolbarModule {}
