export const locale = {
    lang: 'fr',
    data: {
        NAV: {
            APPLICATIONS: 'Configurer',
            USER_MANAGEMENT: 'fr Users Management',
            SURVEY_MANAGEMENT: 'fr Survey Management',
            ORDERS: 'fr Orders',
            ALERTING: 'Alerting',
            ALERT: {
                TITLE: 'Alert'
            },
            INVENTORY: 'fr Inventory',
            REPORTING: 'fr Inventory',
            BACKUPS: {TITLE: 'Backups'},
            RESTORES: {TITLE: 'Restores'},
            EXECUTIONS: {TITLE: 'Executions'},
            SAMPLE: {
                TITLE: 'Configuration de la branche',
                BADGE: '15'
            },
            ACCOUNTING: {
                TITLE: 'Accounting'
            },
            AUDIT_LOG: {
                TITLE: 'Audit Log'
            },
            WAREHOUSE: {
                TITLE: `Configuration de l'entrepôt`
            },
            TENANTS: {
                TITLE: 'Tenants'
            },
            VEHICLE_SKILLS: {
                TITLE: 'fr Vehicle Skills'
            },
            TENANT_MANAGEMENT: {
                TITLE: 'Tenant Management',
                ROLES: {
                    TITLE: 'Roles'
                },
                SUPPLIERS: {
                    TITLE: 'Suppliers'
                },
                MAJOR_ACCOUNT_CODE: {
                    TITLE: 'Codes de compte majeurs'
                },
                SERVICE_PRIORITY_CODE: {
                    TITLE: 'Codes de priorité de service'
                },
                DEPOSIT_ITEM: {
                    TITLE: 'Articles de dépôt'
                },
                PRODUCT_IMEI_TRACK: {
                    TITLE: 'IMEI Tracking'
                },
                STATEMENT_CODE: {
                    TITLE: 'Codes de déclaration'
                },
                DUNNING_MESSAGE_CODE: {
                    TITLE: 'Codes de message de relance'
                },
                CREDIT_CLASS: {
                    TITLE: 'Classe de crédit'
                },
                CREDIT_TERM: {
                    TITLE: 'fr Credit Terms'
                },
                DELIVERY_POINT: {
                    TITLE: 'Points de livraison'
                },
                STOP_TYPE: {
                    TITLE: `Types d'arrêt`
                },
                PAYMENT_TYPE: {
                    TITLE: 'Types de paiement'
                },
                CUSTOMER: {
                    TITLE: 'Les clients'
                },
                CUSTOMER_GROUP: {
                    TITLE: 'fr Customer Groups'
                },
                PROMOTIONS: {
                    TITLE: 'Promotions'
                },
                PRICE_LIST: {
                    TITLE: 'fr Price Lists'
                },
                PRODUCT_CLASS: {
                    TITLE: 'fr Product Class'
                },
                PRODUCT_LIST: {
                    TITLE: 'fr Product Lists'
                },
                PRODUCT_GROUP: {
                    TITLE: 'fr Product Group'
                },
                UNIT_OF_MEASURE: {
                    TITLE: 'fr Unit of Measure'
                },
                PACKAGING: {
                    TITLE: 'fr Packaging'
                },
                PRODUCT_TAX_CATEGORY: {
                    TITLE: 'fr Product Tax Categories'
                },
                PRODUCT: {
                    TITLE: 'fr Products'
                },
                DELIVERY_PACKAGE_TYPE: {
                    TITLE: 'fr Delivery Package Types'
                },
                VEHICLE: {
                    TITLE: 'fr Vehicles'
                },
                VEHICLE_TYPE: {
                    TITLE: 'fr Vehicle Types'
                },
                ROUTE_TYPE: {
                    TITLE: 'fr Route Types'
                },
                ROUTE_MAP_SYMBOL: {
                    TITLE: 'fr Route Map Symbols'
                },
                CORE_REPORTS: {
                    TITLE: 'fr Core Reports'
                },
                TEMPERATURE_REPORTS: {
                    TITLE: 'fr Temperature Reports'
                },
                ROUTE: {
                    TITLE: 'fr Routes'
                },
                ORDER: {
                    TITLE: 'fr Orders'
                },
                SALE_ORDER: {
                    TITLE: 'fr Sale Orders'
                },
                ORDER_REJECTION_CODE: {
                    TITLE: 'fr Order Rejection Codes'
                },
                INCOMING_INVENTORY: {
                    TITLE: 'fr Receive/Adjust Inventory'
                },
                LOAD_ORDER: {
                    TITLE: 'fr Load Orders'
                },
                PICK_ORDER: {
                    TITLE: 'fr Pick Orders'
                },
                PACK_ORDER: {
                    TITLE: 'fr Pack Orders'
                },
                DELIVERY_ORDER: {
                    TITLE: 'fr Delivery Orders'
                },
                CUSTOMER_SMS_TEXT: {
                    TITLE: 'fr Customer SMS Texts'
                },
                TERRITORIES: {
                    TITLE: 'Territories'
                },
                EMPLOYEE: {
                    TITLE: 'fr Employees'
                },
                EMPLOYEE_GROUP: {
                    TITLE: 'fr Employee Groups'
                },
                HANDHELD_PROFILE: {
                    TITLE: 'fr Handheld Profiles'
                },
                IMPORT_DATA: {
                    TITLE: 'Import Data'
                },
                ROUTE_PLANNER: {
                    TITLE: 'fr Route Planner'
                },
                ROUTE_COMMAND: {
                    TITLE: 'fr Route Monitor'
                },
                ROUTE_HISTORY: {
                    TITLE: 'Route History'
                },
                ROUTE_SCHEDULES: {
                    TITLE: 'Route Schedules'
                },
                INVENTORY_STOCK: {
                    TITLE: 'fr Inventory Stock'
                },
                VEHICLE_UNLOADS: {
                    TITLE: 'Vehicle Unloads'
                },
                COMPANY: {
                    TITLE: 'fr Company'
                },
                REPORT: {
                    TITLE: 'fr Reports'
                },
                INVOICE: {
                    TITLE: 'fr Invoices'
                },
                ORGANISATION: {
                    TITLE: 'Organisations'
                },
                STOCK_TAKES: {
                    TITLE: 'fr Stock take'
                },
                WAREHOUSE_TRANSFER: {
                    TITLE: 'Warehouse Transfer'
                },
                PURCHASE_ORDERS: {
                    TITLE: 'fr Purchase Orders'
                },
                VEHICLE_CHECK_LIST: {
                    TITLE: 'Safety Profiles'
                },
                INVENTORY_TRANSACTIONS: {
                    TITLE: 'Inventory Ledger'
                },
                RECIPES: {
                    TITLE: 'Recipes'
                },
                PRODUCTION_VOUCHERS: {
                    TITLE: 'Production Vouchers'
                },
                CUSTOMER_TRANSACTIONS: {
                    TITLE: 'fr Customer Balance Ledger'
                },
                DRIVER_ACCESS_CODES: {
                    TITLE: 'fr Access Codes'
                },
                SHORTFALL_REPORT: {
                    TITLE: 'fr Shortfall Reports'
                },
                APP_REQUEST: {
                    TITLE: 'fr App Requests'
                },
                SURVEY: {
                    TITLE: 'fr Surveys'
                },
                EMPLOYEE_TARGET: {
                    TITLE: 'fr Employee Targets'
                },
                ROUTE_TARGET: {
                    TITLE: 'fr Route Targets'
                },
                REASON_CODE: {
                    TITLE: 'fr Reason Codes'
                },
                PRODUCT_COST_CHANGE: {
                    TITLE: 'fr Product Cost Changes'
                },
                STOCK_CHANGE_CONDITION: {
                    TITLE: 'fr Stock Condition Change'
                },
                DEMAND_REPORT: {
                    TITLE: 'fr Demand Report'
                },
                EOD_SURVEY: {
                    TITLE: 'fr EOD Survey'
                },
                WORKSHOP: {
                    TITLE: 'Workshop'
                },
                RECIPE_EXECUTION_REPORT: {
                    TITLE: 'fr Recipe Execution Report'
                },
                SUPER_ADMIN: {
                    TITLE: 'SUPER ADMIN'
                },
                PROFILES: {
                    TITLE: 'Profiles'
                },
                EOD_INVENTORY_REPORT: {
                    TITLE: 'EOD Inventory Report'
                },
                PURCHASE_PAYMENTS: {
                    TITLE: 'Purchase Payments'
                },
                PURCHASE_CREDIT_NOTE: {
                    TITLE: 'Purchase Debit Notes'
                },
                INTEGRATION_MAPPING: {
                    TITLE: 'Integration Mapping'
                },
                SUPPLIER_TRANSACTIONS: {
                    TITLE: 'Supplier Ledger'
                },
                SUPPLIER_GROUPS: {
                    TITLE: 'Supplier Groups'
                }
            }
        }
    }
};
