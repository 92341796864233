export const locale = {
    lang: 'fr',
    data: {
        error: {
            validation: 'Validation erreur',
            userexists: "L'employé existe déjà"
        },
        common: {
            search: 'Rechercher',
            confirmation: 'Confirmation',
            yes: 'Oui',
            no: 'Non',
            error: 'Erreur',
            ok: 'OK',
            errorMessage: "Erreur interne du serveur. Veuillez contacter l'administrateur.",
            confirmDelete: {
                message: 'Êtes-vous sûr de vouloir supprimer {{ discriminator }}?'
            },
            routePlannerFailedOptimization: {
                message: "L'optimisation a échoué, veuillez vérifier que votre itinéraire / branche a des coordonnées de départ."
            },
            forms: {
                validationMessages: {
                    required: {
                        default: 'Le champ {{_fieldName}} est requis'
                    },
                    floatRange: {
                        default: '{{_the}} {{_fieldName}} devrait être comprise entre {{min}} et {{max}}'
                    },
                    maxlength: {
                        default: '{{_the}} {{_fieldName}} peut avoir un maximum de {{requiredLength}} caractères'
                    },
                    minlength: {
                        default: '{{_the}} {{_fieldName}} doit comporter au moins {{requiredLength}} caractères'
                    },
                    min: {
                        default: '{{_the}} {{_fieldName}} doit être supérieur à {{min}}'
                    },
                    max: {
                        default: '{{_the}} {{_fieldName}} doit être au plus {{max}}'
                    },
                    formValid: {
                        default: 'Vous devez remplir tous les champs obligatoires avec une valeur valide avant de pouvoir enregistrer.'
                    },
                    confirmPassword: {
                        default: 'Confirmer le mot de passe et le mot de passe ne sont pas égaux'
                    },
                    email: {
                        default: 'Email invalide'
                    },
                    timeRange: {
                        default: 'Plage horaire invalide'
                    },
                    maxWeight: {
                        default: 'Poids invalide'
                    }
                },
                unsavedChangesWarning: {
                    default: 'Vous avez des modifications non enregistrées, êtes-vous sûr de vouloir continuer?'
                }
            },
            selection: {
                selectAll: 'Tout',
                selectNone: 'Aucun'
            },
            genderWords: {
                m: {
                    _a: 'Un',
                    _the: 'Le'
                },
                f: {
                    _a: 'Une',
                    _the: 'La'
                }
            }
        }
    }
};
