<div id="login" fxLayout="column" fusePerfectScrollbar>
    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div id="login-form" *fuseIfOnDom [@animate]="{value: '*', params: {duration: '300ms', y: '100px'}}">
            <div class="logo">
                <img src="assets/images/logos/newlogo.png" />
            </div>

            <div *ngIf="authenticationError || authenticationTenantError" class="message-box error" style="margin-bottom: 16px">
                Failed to create account! Please check your credentials and try again!
            </div>
            <div *ngIf="invalidInviteCode" class="message-box error" style="margin-bottom: 16px">Please provide a valid invite code.</div>
            <div *ngIf="accountCreated" class="message-box" style="background-color: green; color: white; margin-bottom: 16px">
                Account has been created, please
                <a [routerLink]="'/login'">login</a>.
            </div>
            <div
                *ngIf="accountForm.get('password').value !== accountForm.get('confirmPassword').value && accountForm.get('password').touched"
                class="message-box error"
                style="margin-bottom: 16px">
                The passwords don't match, please check and try again.
            </div>
            <form name="createAccountForm" [formGroup]="accountForm" novalidate (submit)="createAccount()">
                <nz-form-item [formGroup]="accountForm">
                    <nz-form-control [nzValidateStatus]="accountFormErrors.email.required ? 'error' : null" nzErrorTip="Email is required">
                        <input nz-input #inputElement type="email" formControlName="email" placeholder="Email" />
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item [formGroup]="accountForm">
                    <nz-form-control [nzValidateStatus]="accountFormErrors.password.required ? 'error' : null" nzErrorTip="Password is required">
                        <input nz-input #inputElement type="password" formControlName="password" placeholder="Password" />
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item [formGroup]="accountForm">
                    <nz-form-control
                        [nzValidateStatus]="accountFormErrors.confirmPassword.required ? 'error' : null"
                        nzErrorTip="Password is required">
                        <input nz-input #inputElement type="password" formControlName="confirmPassword" placeholder="Confirm Password" />
                    </nz-form-control>
                </nz-form-item>

                <!--<nz-form-item [formGroup]="accountForm">
                    <nz-form-control [nzValidateStatus]="accountFormErrors.invitationCode.required ? 'error' : null"
                        nzErrorTip="Invitation code is required">
                            <input nz-input #inputElement
                                formControlName="invitationCode"
                                placeholder="Invitation Code">
                    </nz-form-control>
                </nz-form-item>-->

                <nz-form-item [formGroup]="accountForm">
                    <nz-form-control [nzValidateStatus]="accountFormErrors.tenant.required ? 'error' : null" nzErrorTip="Tenant is required">
                        <input nz-input #inputElement [readonly]="true" formControlName="tenant" placeholder="Tenant" />
                    </nz-form-control>
                </nz-form-item>

                <div fxLayout="column" fxLayoutAlign="start center">
                    <button
                        nz-button
                        nzSize="large"
                        nzType="primary"
                        class="submit-button"
                        aria-label="CREATE ACCOUNT"
                        [disabled]="accountForm.invalid">
                        CREATE ACCOUNT
                    </button>
                    <a [routerLink]="'/login'"> Login </a>
                </div>
            </form>
        </div>
    </div>
</div>
