<!-- Chat Container -->
<div class="chat-container">
    <div class="customer-chat" [ngClass]="{hide: !showChatWindow}">
        <div id="customer-chat-container"></div>
    </div>
    <div>
        <button class="acOpenButton acButtonStyles" (click)="showChat($event)" [ngClass]="{hide: showChatWindow}">
            <svg focusable="false" aria-hidden="true" width="20px" height="20px" viewBox="0 0 20 20">
                <title>Open Chat</title>
                <g id="open_chat" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path
                        d="M18,0 L2,0 C0.9,0 0,0.9 0,2 L0,20 L4,16 L18,16 C19.1,16 20,15.1 20,14 L20,2 C20,0.9 19.1,0 18,0 Z M18,14 L4,14 L2,16 L2,2 L18,2 L18,14 Z"
                        id="Shape"
                        fill="white"
                        fill-rule="nonzero"></path>
                </g>
            </svg>
        </button>
        <button class="acCloseButton acButtonStyles" (click)="closeChat($event)" [ngClass]="{hide: !showChatWindow}">
            <svg width="18px" height="10px" viewBox="0 0 18 10">
                <title>Close Chat</title>
                <g id="min_chat" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path
                        d="M2.75838926,0.444191344 L9.00805369,6.33637054 L15.2577181,0.444191344 C15.885906,-0.148063781 16.9006711,-0.148063781 17.5288591,0.444191344 C18.157047,1.03644647 18.157047,1.99316629 17.5288591,2.58542141 L10.1355705,9.55580866 C9.50738255,10.1480638 8.49261745,10.1480638 7.86442953,9.55580866 L0.47114094,2.58542141 C-0.15704698,1.99316629 -0.15704698,1.03644647 0.47114094,0.444191344 C1.09932886,-0.132877752 2.13020134,-0.148063781 2.75838926,0.444191344 Z"
                        id="Path"
                        fill="white"
                        fill-rule="nonzero"></path>
                </g>
            </svg>
        </button>
    </div>
</div>
