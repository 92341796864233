import {Router} from '@angular/router';
import {StateStorageService} from 'app/core/auth/state-storage.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {SuperUserService} from 'app/core/auth/super-user.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {LoginService} from 'app/core/login/login.service';

@Component({
    selector: 'sa-login',
    templateUrl: './sa-login.component.html',
    styleUrls: ['./sa-login.component.scss'],
    animations: fuseAnimations
})
export class SaLoginComponent implements OnInit, OnDestroy {
    loginForm: UntypedFormGroup;
    loginFormErrors: any;
    authenticationError: boolean;
    authenticationTenantError: boolean;
    inactiveUser: boolean;
    credentials: any;

    // Private
    private _unsubscribeAll: Subject<void>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {UntypedFormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: UntypedFormBuilder,
        private loginService: LoginService,
        private stateStorageService: StateStorageService,
        // private elementRef: ElementRef,
        // private renderer: Renderer,
        private router: Router,
        private superUserService: SuperUserService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                }
            }
        };

        // Set the defaults
        this.loginFormErrors = {
            username: {},
            password: {},
            tenant: {}
        };

        this.superUserService.setIsSuperUser(true);

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', Validators.required],
            tenant: ['rm_admin'],
            rememberMe: [true]
        });

        this.loginForm.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            this.onLoginFormValuesChanged();
        });
    }

    login(): void {
        this.loginService
            .login(this.loginForm.value)
            .then(() => {
                this.authenticationError = false;
                this.authenticationTenantError = false;
                if (/^\/reset\//.test(this.router.url)) {
                    this.router.navigate(['']);
                }

                // this.eventManager.broadcast({
                //     name: 'authenticationSuccess',
                //     content: 'Sending Authentication Success'
                // });

                // // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // // since login is succesful, go to stored previousState and clear previousState
                const redirect = this.stateStorageService.getUrl();
                if (redirect) {
                    this.stateStorageService.storeUrl(null);
                    this.router.navigate([redirect]);
                } else {
                    this.router.navigate(['/']);
                }
            })
            .catch((e) => {
                if (e.error.detail === 'Bad credentials') {
                    this.authenticationTenantError = false;
                    this.authenticationError = true;
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On form values changed
     */
    onLoginFormValuesChanged(): void {
        for (const field in this.loginFormErrors) {
            if (!this.loginFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.loginFormErrors[field] = {};

            // Get the control
            const control = this.loginForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.loginFormErrors[field] = control.errors;
            }
        }
    }
}
