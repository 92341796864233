<div id="login" fxLayout="column" fusePerfectScrollbar>
    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div id="login-form" *fuseIfOnDom [@animate]="{value: '*', params: {duration: '300ms', y: '100px'}}">
            <div class="logo">
                <img src="assets/images/logos/newlogo.png" />
            </div>

            <div *ngIf="authenticationError" class="message-box error" style="margin-bottom: 16px">
                Failed to sign in! Please check your credentials and try again!
            </div>
            <form name="loginForm" [formGroup]="loginForm" novalidate (submit)="login()">
                <nz-form-item [formGroup]="loginForm">
                    <nz-form-control [nzValidateStatus]="loginFormErrors.username.required ? 'error' : null" nzErrorTip="Username is required">
                        <input nz-input #inputElement formControlName="username" placeholder="Username" />
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item [formGroup]="loginForm">
                    <nz-form-control [nzValidateStatus]="loginFormErrors.password.required ? 'error' : null" nzErrorTip="Password is required">
                        <input nz-input #inputElement type="password" formControlName="password" placeholder="Password" />
                    </nz-form-control>
                </nz-form-item>
                <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center">
                    <label nz-checkbox formControlName="rememberMe">Remember Me</label>
                </div>
                <div fxLayout="column" fxLayoutAlign="start center">
                    <button nz-button nzSize="large" nzType="primary" class="submit-button" aria-label="LOG IN" [disabled]="loginForm.invalid">
                        LOGIN
                    </button>
                    <a class="forgot-password" [routerLink]="'/login'"> Login as User? </a>
                </div>
            </form>
        </div>
    </div>
</div>
