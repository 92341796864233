<div class="call-in" class="mb-16">
    <div fxLayout="row" fxLayoutAlign="start center" class="di-modal-title">
        <h2>Login As Different User</h2>
    </div>
    <div class="di-modal-body">
        <div [formGroup]="userForm">
            <div style="margin-top: 10px; flex-direction: row">
                <div [formGroup]="userForm" fxLayout="column">
                    <h3 style="width: 40%; margin-top: 5px; text-align: left; padding-right: 5px">
                        <label>User</label>
                    </h3>
                    <mpos-search-select
                        field="user"
                        [options$]="users"
                        [customSearchFn]="searchUser"
                        displayAttribute="firstName"
                        suppressLabel="true"></mpos-search-select>
                </div>
            </div>
            <div style="margin-top: 15px; flex-direction: row">
                <button style="width: 100%" nz-button nzType="primary" class="submit-button" aria-label="VERIFY" (click)="proceed($event)">
                    Procced
                </button>
            </div>
        </div>
    </div>
</div>
