import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable, Subject, from, of} from 'rxjs';
import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {NZ_MODAL_DATA, NzModalRef} from 'ng-zorro-antd/modal';
import {EmployeeResource} from 'app/blocks/resource/employee-resource';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';
import {locale as english} from 'app/main/public/authentication/i18n/en';
import {UserResource} from 'app/blocks/resource/user-resource';
import {NamedListService} from 'app/blocks/service/named-list.service';
import {Resource} from 'app/constants/resource';
import {SuperUserService} from 'app/core/auth/super-user.service';
import {Store} from '@ngxs/store';
import {GetConfig} from 'app/blocks/store/actions/common-actions';
import {RoleService} from 'app/blocks/service/api/role.service';
import {LoginService} from 'app/core/login/login.service';

@Component({
    selector: 'different-user',
    templateUrl: './different-user.html',
    styleUrls: ['./different-user.scss'],
    animations: fuseAnimations
})
export class DifferentUserComponent implements OnInit, OnDestroy {
    userForm: UntypedFormGroup;

    // Private
    private _unsubscribeAll: Subject<void>;

    public users: Observable<any>;
    public loginData = null;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {UntypedFormBuilder} _formBuilder
     */
    constructor(
        @Inject(NZ_MODAL_DATA) public modalData: any,
        private _fuseConfigService: FuseConfigService,
        private loginService: LoginService,
        private _formBuilder: UntypedFormBuilder,
        private _usersResource: UserResource,
        private _nameListService: NamedListService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _superUserService: SuperUserService,
        public modal: NzModalRef
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english);
        this.users = this._nameListService.for(Resource.USERS);
        this.loginData = this.modalData;
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.userForm = this._formBuilder.group({
            user: ['', [Validators.required]]
        });
    }

    proceed(e): void {
        const user = this.userForm.get('user').value;
        this._superUserService.setDifferentUser(user.id);
        const userData = this.modalData.data;
        userData.userId = user.id;
        this.loginService.login(userData).then(() => {
            this.modal.close();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On form values changed
     */

    searchUser = (term): Observable<any[]> => {
        return from(
            this._usersResource.api.search({
                page: 0,
                query: term,
                size: 5000,
                sort: 'id,asc'
            })
        );
    };
}
