import {Router} from '@angular/router';
import {StateStorageService} from 'app/core/auth/state-storage.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {SuperUserService} from 'app/core/auth/super-user.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {LoginService} from 'app/core/login/login.service';
import {MfaComponent} from 'app/main/public/authentication/mfa/mfa.component';
import {NzModalService} from 'ng-zorro-antd/modal';
import {MfaService} from 'app/blocks/service/api/mfa.service';
import {Store} from '@ngxs/store';
import {DifferentUserComponent} from 'app/main/public/authentication/different-user/different-user.component';
import {Principal} from 'app/core/auth/principal.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: fuseAnimations
})
export class LoginComponent implements OnInit, OnDestroy {
    loginForm: UntypedFormGroup;
    loginFormErrors: any;
    authenticationError: boolean;
    authenticationTenantError: boolean;
    inactiveUser: boolean;
    credentials: any;
    isWhitespacePresentInTenantName = false;
    deactivatedLogin = false;
    // Private
    private _unsubscribeAll: Subject<void>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {UntypedFormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: UntypedFormBuilder,
        private loginService: LoginService,
        private stateStorageService: StateStorageService,
        private _modalService: NzModalService,
        private mfaService: MfaService,
        // private elementRef: ElementRef,
        // private renderer: Renderer,
        private router: Router,
        private _store: Store,
        private superUserService: SuperUserService,
        private _principal: Principal,
        private _toastService: ToastrService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                }
            }
        };

        // Set the defaults
        this.loginFormErrors = {
            username: {},
            password: {},
            tenant: {}
        };
        this.superUserService.setIsSuperUser(false);

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginService.clearAllTokens();
        this.loginForm = this._formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', Validators.required],
            tenant: ['', Validators.required],
            differentUser: [false],
            rememberMe: [false],
            userId: [null]
        });

        this.loginForm.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            this.onLoginFormValuesChanged();
        });
    }

    login(): void {
        this.loginService
            .login(this.loginForm.value)
            .then(() => {
                this.mfaService.isMfaEnabled().then((result) => {
                    const isMfaEnabled = result.body.isMfaEnabled;
                    if (isMfaEnabled) {
                        this.openMfa();
                    } else {
                        if (this.loginForm.value.differentUser) {
                            this.loginDifferentUser();
                        } else {
                            this.authenticationError = false;
                            this.authenticationTenantError = false;

                            if (/^\/reset\//.test(this.router.url)) {
                                this.router.navigate(['']);
                            }

                            // this.eventManager.broadcast({
                            //     name: 'authenticationSuccess',
                            //     content: 'Sending Authentication Success'
                            // });

                            // // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                            // // since login is succesful, go to stored previousState and clear previousState
                            const redirect = this.stateStorageService.getUrl();
                            if (redirect) {
                                this.stateStorageService.storeUrl(null);
                                this.router.navigate([redirect]);
                            } else {
                                this.router.navigate(['/']);
                            }
                        }
                    }
                });
            })
            .catch((e) => {
                if (e.error.detail === 'Bad credentials') {
                    this.authenticationTenantError = false;
                    this.authenticationError = true;
                    this.deactivatedLogin = false;
                } else if (e.error.detail === 'Inactive User') {
                    this.authenticationTenantError = false;
                    this.authenticationError = false;
                    this.inactiveUser = true;
                    this.deactivatedLogin = false;
                } else if (e.error.detail === 'Tenant Login Not Enabled') {
                    this.authenticationTenantError = false;
                    this.authenticationError = false;
                    this.inactiveUser = false;
                    this.deactivatedLogin = true;
                } else {
                    this.authenticationTenantError = true;
                    this.authenticationError = false;
                    this.inactiveUser = false;
                    this.deactivatedLogin = false;
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On form values changed
     */
    onLoginFormValuesChanged(): void {
        for (const field in this.loginFormErrors) {
            if (!this.loginFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.loginFormErrors[field] = {};

            // Get the control
            const control = this.loginForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.loginFormErrors[field] = control.errors;
            }

            //whitespace not allowed
            const tenantName = this.loginForm.get('tenant').value;
            if (tenantName != null && tenantName.indexOf(' ') >= 0) {
                this.isWhitespacePresentInTenantName = true;
            } else {
                this.isWhitespacePresentInTenantName = false;
            }
        }
    }

    loginDifferentUser(): void {
        this._principal.hasAnyAuthority(['ROLE_ADMIN']).then((result) => {
            if (result) {
                this._modalService
                    .create({
                        nzContent: DifferentUserComponent,
                        nzData: {data: this.loginForm.value},
                        nzClosable: true,
                        nzFooter: null
                    })
                    .afterClose.pipe()
                    .subscribe((result) => {
                        this.authenticationError = false;
                        this.authenticationTenantError = false;

                        if (/^\/reset\//.test(this.router.url)) {
                            this.router.navigate(['']);
                        }

                        const redirect = this.stateStorageService.getUrl();
                        if (redirect) {
                            this.stateStorageService.storeUrl(null);
                            this.router.navigate([redirect]);
                        } else {
                            this.router.navigate(['/']);
                        }
                    });
            } else {
                this._toastService.error('You are not authorized to login as different user');
            }
        });
    }

    openMfa(): void {
        this._modalService
            .create({
                nzContent: MfaComponent,
                nzData: {},
                nzClosable: true,
                nzFooter: null
            })
            .afterClose.pipe()
            .subscribe((result) => {
                if (result) {
                    this.authenticationError = false;
                    this.authenticationTenantError = false;
                    if (/^\/reset\//.test(this.router.url)) {
                        this.router.navigate(['']);
                    }
                    const redirect = this.stateStorageService.getUrl();
                    if (redirect) {
                        this.stateStorageService.storeUrl(null);
                        this.router.navigate([redirect]);
                    } else {
                        this.router.navigate(['/']);
                    }
                }
            });
    }

    getErrorToolTipCaptionForTenant(): string {
        return this.isWhitespacePresentInTenantName ? 'Client ID cannot have space' : 'Client ID is required';
    }
}
