<div class="col-12 col account-menu" fxFlex>
    <!-- <div fxFlex fxFill fxLayout="row" fxLayoutAlign="end center">
            <h1>{{companyName}}</h1>
        </div>

        <div fxFlex fxFill fxLayout="row" fxLayoutAlign="end center"> -->

    <!-- <div fxFlex fxFill fxLayout="row" fxLayoutAlign="space-between center"> -->
    <!-- <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <div class="px-12" *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <fa-icon [icon]="faMenu"></fa-icon>
            </div>
        </div> -->

    <!-- <div fxFlex fxLayout="row" fxLayoutAlign="end end">
            <span *ngIf="logoSrc == null">{{ companyName }}</span>
            <img
                *ngIf="logoSrc !== null"
                [height]="logoHeight"
                [width]="logoWidth"
                class="image"
                [src]="_domSanitizer.bypassSecurityTrustUrl(logoSrc)"
                alt="Company Logo" />
        </div> -->

    <div class="col-12 col">
        <a nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
            <div fxLayout="row" fxLayoutAlign="center center">
                <img class="avatar mr-0 mr-sm-16" src="assets/images/avatars/profile.jpg" />
                <span class="username mr-12" fxHide fxShow.gt-sm>{{ name }}</span>
                <!-- <fa-icon fxHide.xs [icon]="faAngleDown"></fa-icon> -->
            </div>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
                <a
                    nz-menu-item
                    style="text-decoration: none; display: block; width: 100%"
                    target="_blank"
                    href="https://datainsightshelp.freshdesk.com/support/home">
                    <fa-icon [icon]="faQuestionCircle" class="mr-8"></fa-icon>
                    <span>Help</span>
                </a>
                <a nz-menu-item style="text-decoration: none; display: block; width: 100%" target="_blank" [href]="getApiDocsUrl()">
                    <fa-icon [icon]="apiDocsIcon" class="mr-8"></fa-icon>
                    <span>API Docs</span>
                </a>
                <li nz-menu-item style="text-decoration: none" (click)="logout()">
                    <fa-icon id="logout" [icon]="faSignOutAlt" class="mr-8"></fa-icon>
                    <span>Logout</span>
                </li>
            </ul>
        </nz-dropdown-menu>

        <!--
            <nz-divider nzType="vertical"></nz-divider>

            <div class="px-24 py-12">
                <a nz-dropdown [nzDropdownMenu]="langMenu">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <img class="flag mr-8" [src]="'assets/images/flags/' + selectedLanguage?.flag + '.png'" />
                        <span class="iso text-uppercase">{{ selectedLanguage?.id }}</span>
                    </div>
                </a>
                <nz-dropdown-menu #langMenu="nzDropdownMenu">
                    <ul nz-menu nzSelectable>
                        <li nz-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang, true)">
                            <span fxLayout="row" fxLayoutAlign="start center">
                                <img class="flag mr-16" [src]="'assets/images/flags/' + lang.flag + '.png'" />
                                <span class="iso">{{ lang.title }}</span>
                            </span>
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </div> -->
    </div>
    <!-- </div> -->
</div>
<!-- <div class="app-version">v{{ appVersionNo }}</div> -->
