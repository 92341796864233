<div class="call-in" class="mb-16">
    <div fxLayout="row" fxLayoutAlign="start center" class="di-modal-title">
        <h2>MFA Verification</h2>
    </div>
    <div class="di-modal-body">
        <div [formGroup]="mfaForm">
            <div *ngIf="!isMfaVerified" class="message-box error" style="margin-bottom: 16px">MFA Verification Failed!</div>
            <div style="margin-top: 10px; flex-direction: row">
                <h3 style="width: 40%; margin-top: 5px; text-align: left; padding-right: 5px">
                    <label>Code</label>
                </h3>
                <input nz-input formControlName="mfaCode" placeholder="Enter Code from Authenticator" />
            </div>
            <div style="margin-top: 15px; flex-direction: row">
                <button style="width: 100%" nz-button nzType="primary" class="submit-button" aria-label="VERIFY" (click)="verify($event)">
                    VERIFY
                </button>
            </div>
        </div>
    </div>
</div>
