import {Principal} from 'app/core/auth/principal.service';
import {NgModule, LOCALE_ID, ModuleWithProviders} from '@angular/core';
import {DatePipe, registerLocaleData} from '@angular/common';
import {Title} from '@angular/platform-browser';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeEnIn from '@angular/common/locales/en-IN';
import {LoginService} from 'app/core/login/login.service';
import {AccountService} from 'app/core/auth/account.service';
import {StateStorageService} from 'app/core/auth/state-storage.service';
import {CSRFService} from 'app/core/auth/csrf.service';
import {AuthServerProvider} from 'app/core/auth/auth-jwt.service';
import {UserService} from 'app/core/user/user.service';
import {UserRouteAccessService} from 'app/core/auth/user-route-access-service';
import {NGXS_PLUGINS} from '@ngxs/store';
import {logoutPlugin} from 'app/blocks/store/actions/logout-reducer';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: []
})
export class MposCoreModule {
    constructor() {
        registerLocaleData(localeEn);
        registerLocaleData(localeFr);
        registerLocaleData(localeEnIn);
    }

    static forRoot(): ModuleWithProviders<MposCoreModule> {
        return {
            ngModule: MposCoreModule,
            providers: [
                LoginService,
                AccountService,
                StateStorageService,
                Principal,
                CSRFService,
                AuthServerProvider,
                UserService,
                UserRouteAccessService,
                Title,
                {
                    provide: LOCALE_ID,
                    useValue: 'en'
                },
                {
                    provide: NGXS_PLUGINS,
                    useValue: logoutPlugin,
                    multi: true
                },
                DatePipe
            ]
        };
    }
}
