import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';

import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AccountMenuComponent} from 'app/layout/components/account-menu/account-menu.component';

@NgModule({
    declarations: [AccountMenuComponent],
    imports: [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,

        NzDropDownModule,
        NzButtonModule,
        NzDividerModule,
        FontAwesomeModule
    ],
    exports: [AccountMenuComponent]
})
export class AccountMenuModule {}
