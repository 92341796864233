import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {FuseSharedModule} from '@fuse/shared.module';
import {RedirectHandlerComponent} from 'app/main/public/authentication/redirect-handler/redirect-handler.component';

@NgModule({
    declarations: [RedirectHandlerComponent],
    imports: [RouterModule, FuseSharedModule]
})
export class RedirectHandlerModule {}
