<div id="login" fxLayout="column" fusePerfectScrollbar>
    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div id="login-form" *fuseIfOnDom [@animate]="{value: '*', params: {duration: '300ms', y: '100px'}}">
            <div class="logo">
                <img src="assets/images/logos/newlogo.png" />
            </div>
            <form name="loginForm" [formGroup]="loginForm" novalidate (submit)="login()">
                <nz-form-item [formGroup]="this.loginForm">
                    <nz-form-control
                        [nzValidateStatus]="isWhitespacePresentInTenantName ? 'error' : null"
                        [nzErrorTip]="getErrorToolTipCaptionForTenant()">
                        <input id="input-tenant" nz-input #inputElement formControlName="tenant" placeholder="Client ID" />
                    </nz-form-control>
                </nz-form-item>

                <div fxLayout="column" fxLayoutAlign="start center">
                    <button
                        id="button-login"
                        nz-button
                        nzSize="large"
                        nzType="primary"
                        class="submit-button"
                        aria-label="LOG IN"
                        [disabled]="loginForm.invalid || isWhitespacePresentInTenantName">
                        LOGIN VIA SSO
                    </button>
                    <a class="forgot-password" [routerLink]="'/login'"> Login via Credentials </a>
                    <a class="forgot-password" [routerLink]="'/super-admin/login'"> Login as Super Admin? </a>
                </div>
            </form>
        </div>
    </div>
</div>
