import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';

import {FuseSharedModule} from '@fuse/shared.module';
import {ClientPortalRouteMagicAssistantComponent} from 'app/layout/components/routemagic-assistant/client-portal-routemagic-assistant/client-portal-routemagic-assistant.component';

@NgModule({
    declarations: [ClientPortalRouteMagicAssistantComponent],
    imports: [RouterModule, MatButtonModule, MatIconModule, MatToolbarModule, FuseSharedModule],
    exports: [ClientPortalRouteMagicAssistantComponent]
})
export class ClientPortalRouteMagicAssistantModule {}
