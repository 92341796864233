import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';

import {FuseSharedModule} from '@fuse/shared.module';

import {LoginViaSSOComponent} from 'app/main/public/authentication/login-via-sso/login-via-sso.component';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzFormModule} from 'ng-zorro-antd/form';
import {MfaModule} from 'app/main/public/authentication/mfa/mfa.module';

@NgModule({
    declarations: [LoginViaSSOComponent],
    imports: [
        // RouterModule.forChild(routes),
        RouterModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        NzCheckboxModule,
        NzButtonModule,
        NzFormModule,
        NzInputModule,
        FuseSharedModule,
        MfaModule
    ]
})
export class LoginViaSSOModule {}
