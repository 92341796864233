import {BrowserAgent} from '@newrelic/browser-agent/loaders/browser-agent';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule, APP_INITIALIZER, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatStepperModule} from '@angular/material/stepper';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {FuseSidebarModule, FuseThemeOptionsModule, FuseProgressBarModule} from '@fuse/components';
import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {TranslateModule} from '@ngx-translate/core';
import {NgxsModule} from '@ngxs/store';
import {AppComponent} from 'app/app.component';
import {appStates} from 'app/blocks/store/states/app-state';
import {AlertService} from 'app/common/alert/alert.service';
import {UserRouteAccessService} from 'app/core/auth/user-route-access-service';
import {LoginService} from 'app/core/login/login.service';

import {fuseConfig} from 'app/fuse-config';
import {LayoutModule} from 'app/layout/layout.module';
import {LoginComponent} from 'app/main/public/authentication/login/login.component';
import {SaLoginComponent} from 'app/main/public/sa-authentication/sa-login/sa-login.component';
import {SaLoginModule} from 'app/main/public/sa-authentication/sa-login/sa-login.module';
import {ToastrModule} from 'ngx-toastr';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {NgxWebstorageModule} from 'ngx-webstorage';

import {AuthExpiredInterceptor} from 'app/blocks/interceptor/auth-expired.interceptor';
import {AuthInterceptor} from 'app/blocks/interceptor/auth.interceptor';
import {ErrorHandlerInterceptor} from 'app/blocks/interceptor/errorhandler.interceptor';
import {NotificationInterceptor} from 'app/blocks/interceptor/notification.interceptor';
import {DialogModule} from 'app/common/dialog/dialog.module';
import {MposCoreModule} from 'app/core/core.module';
import {LoginModule} from 'app/main/public/authentication/login/login.module';
import {AppInitializer} from 'app/common/app-initializer/app-initializer';
// import { AlertModule } from './common/alert/alert.module';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
// import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import {AngularDualListBoxModule} from 'angular-dual-listbox';
import {CanDeactivateGuard} from 'app/common/can-deactivate-guard.service';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgMapsCoreModule} from '@ng-maps/core';
import {DndModule} from '@ng-dnd/core';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {ForgotPasswordComponent} from 'app/main/public/authentication/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from 'app/main/public/authentication/reset-password/reset-password.component';
import {ResetPasswordModule} from 'app/main/public/authentication/reset-password/reset-password.module';
import {ForgotPasswordModule} from 'app/main/public/authentication/forgot-password/forgot-password.module';
import {NZ_I18N, en_US} from 'ng-zorro-antd/i18n';
import {NzConfig, NZ_CONFIG} from 'ng-zorro-antd/core/config';
import {PerfectScrollbarModule} from 'perfect-scrollbar-angular';
import {PERFECT_SCROLLBAR_CONFIG} from 'perfect-scrollbar-angular';
import {PerfectScrollbarConfigInterface} from 'perfect-scrollbar-angular';
import {CreateAccountComponent} from 'app/main/public/authentication/create-account/create-account.component';
import {CreateAccountModule} from 'app/main/public/authentication/create-account/create-account.module';
import {KeyboardShortcutsModule} from 'ng-keyboard-shortcuts';
import {ShortcutsComponent} from 'app/shortcuts/shortcut.component';
import {environment} from 'environments/environment';
import {GlobalErrorInterceptor} from 'app/blocks/interceptor/global-error.interceptor';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {StorageSerializer} from 'app/blocks/util/storage-serializer';
import {GOOGLE_MAPS_API_CONFIG, NgMapsGoogleModule} from '@ng-maps/google';
import {LoginViaSSOComponent} from 'app/main/public/authentication/login-via-sso/login-via-sso.component';
import {LoginViaSSOModule} from 'app/main/public/authentication/login-via-sso/login-via-sso.module';
import {RedirectHandlerModule} from 'app/main/public/authentication/redirect-handler/redirect-handler.module';
import {RedirectHandlerComponent} from 'app/main/public/authentication/redirect-handler/redirect-handler.component';
import {GOOGLE_MAPS_API_KEY} from 'app/app.constants';
import {ALL_ACCESS, SUPER_ADMIN_ACCESS} from 'app/constants/access-levels';
import {NgxStripeModule} from 'ngx-stripe';
import {ServiceWorkerModule} from '@angular/service-worker';
import {NzNotificationModule} from 'ng-zorro-antd/notification';
import {RouteMagicAssistantModule} from 'app/layout/components/routemagic-assistant/routemagic-assistant.module';
import {SwCacheService} from 'app/common/sw-cache/sw-cache.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

const ngZorroConfig: NzConfig = {
    // message: { nzTop: 120 },
    // notification: { nzTop: 240 }
    form: {nzNoColon: true}
};

const appRoutes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'redirect',
        component: RedirectHandlerComponent
    },
    {
        path: 'login-via-sso',
        component: LoginViaSSOComponent
    },
    {
        path: 'create-account',
        component: CreateAccountComponent
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent
    },
    {
        path: 'super-admin/login',
        component: SaLoginComponent
    },
    {
        path: '',
        loadChildren: () => import('./main/private/welcome/welcome.module').then((m) => m.WelcomeModule),
        pathMatch: 'full',
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        }
    },
    {
        path: 'setup',
        loadChildren: () => import('./main/private/setup/setup.module').then((m) => m.SetupModule),
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        }
    },
    {
        path: 'super-admin-setup',
        loadChildren: () => import('./main/private/super-admin-setup/super-admin-setup.module').then((m) => m.SuperAdminSetupModule),
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: SUPER_ADMIN_ACCESS
        }
    },
    {
        path: 'history',
        loadChildren: () => import('./main/private/history/history.module').then((m) => m.HistoryModule),
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        }
    },
    {
        path: 'service-orders',
        loadChildren: () => import('./main/private/service-orders/service-orders.module').then((m) => m.ServiceOrdersModule),
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        }
    },
    {
        path: 'scheduling',
        loadChildren: () => import('./main/private/scheduling/scheduling.module').then((m) => m.SchedulingModule),
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        }
    },
    {
        path: 'reporting',
        loadChildren: () => import('./main/private/reporting/reporting.module').then((m) => m.ReportingModule),
        pathMatch: 'full',
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        }
    },
    {
        path: 'analytics',
        loadChildren: () => import('./main/private/analytics/analytics.module').then((m) => m.AnalyticsModule),
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        }
    }
];

// Noop handler for factory function (for loading AppInitializer at start)
export function appInitializerLoadingBait(): any {
    return function (): any {};
}

@NgModule({
    declarations: [AppComponent, ShortcutsComponent],
    imports: [
        SaLoginModule,
        LoginModule,
        LoginViaSSOModule,
        RedirectHandlerModule,
        CreateAccountModule,
        // DailySummaryModule,
        // CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {}),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatStepperModule,
        MatDialogModule,
        MatCheckboxModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        // AppStoreModule,

        // MPOS newly added
        ToastrModule.forRoot({
            onActivateTick: true
        }),
        AngularDualListBoxModule,
        // MatDialogModule,
        // MposConfirmDialogModule,
        DialogModule.forRoot(),

        // Ng2Webstorage.forRoot({ prefix: 'mpos', separator: '-' }),
        NgxWebstorageModule.forRoot({prefix: 'mpos', separator: '-'}),
        MposCoreModule.forRoot(),
        NgSelectModule,
        NzNotificationModule,
        // AlertModule.forRoot(),
        NgxsModule.forRoot(appStates),
        NgxsStoragePluginModule.forRoot({
            serialize: StorageSerializer.serializeWithType,
            deserialize: StorageSerializer.deserializeWithType
        }),
        FontAwesomeModule,
        NgMapsCoreModule,
        NgMapsGoogleModule,
        DndModule.forRoot({backend: HTML5Backend}),
        // NgxsRouterPluginModule.forRoot(),
        ForgotPasswordModule,
        ResetPasswordModule,
        // NgZorroAntdModule,
        PerfectScrollbarModule,
        // The following plugin should appear last in the imports section
        // Otherwise won't work correctly
        NgxsReduxDevtoolsPluginModule.forRoot(),

        // Following is for KeyboardShort cuts
        KeyboardShortcutsModule.forRoot(),
        // For contextmenu
        // ContextMenuModule.forRoot()

        //For using Stripe payment gateway across the application
        NgxStripeModule.forRoot(),

        ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production}), // change to environment.production to enable
        RouteMagicAssistantModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
            deps: [LocalStorageService, SessionStorageService, SwCacheService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthExpiredInterceptor,
            multi: true,
            deps: [LoginService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true
            // deps: [JhiEventManager]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NotificationInterceptor,
            multi: true
            // deps: [Injector]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerLoadingBait,
            deps: [AppInitializer],
            multi: true
        },
        {
            provide: NZ_I18N,
            useValue: en_US
        },
        {
            provide: NZ_CONFIG,
            useValue: ngZorroConfig
        },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorInterceptor
        },
        {
            provide: GOOGLE_MAPS_API_CONFIG,
            useValue: {
                apiKey: GOOGLE_MAPS_API_KEY
            }
        },
        {provide: BrowserAgent, useValue: environment.production ? new BrowserAgent(environment.newrelic) : null},
        AlertService,
        CanDeactivateGuard,
        SwCacheService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule {}
