import {PackOrderResource} from 'app/blocks/resource/pack-order-resource';
import {PickOrderResource} from 'app/blocks/resource/pick-order-resource';
import {LoadOrderResource} from 'app/blocks/resource/load-order-resource';
import {SaleOrderResource} from 'app/blocks/resource/sale-order-resource';
import {Component, AfterViewInit} from '@angular/core';
import {ShortcutInput} from 'ng-keyboard-shortcuts';
import {InvoiceResource} from 'app/blocks/resource/invoice-resource';
import {Router} from '@angular/router';

@Component({
    selector: 'shortcuts-component',
    template: `<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>`
})
export class ShortcutsComponent implements AfterViewInit {
    shortcuts: ShortcutInput[] = [];

    constructor(
        private saleOrderResource: SaleOrderResource,
        private loadOrderResource: LoadOrderResource,
        private pickOrderResource: PickOrderResource,
        private packOrderResource: PackOrderResource,
        private invoiceResource: InvoiceResource,
        private router: Router
    ) {}

    ngAfterViewInit(): void {
        this.shortcuts.push(
            {
                key: ['alt + o', 'alt + o'],
                label: 'New Sale Order',
                description: 'Alt + o',
                command: () => this.saleOrderResource.navigate.toNew(),
                preventDefault: true
            },
            {
                key: ['cmd + l', 'ctrl + l'],
                label: 'New Load Order',
                description: 'Command + l',
                command: () => this.loadOrderResource.navigate.toNew(),
                preventDefault: true
            },
            {
                key: ['cmd + p', 'ctrl + p'],
                label: 'Pick Order Screen',
                description: 'Command + p',
                command: () => this.pickOrderResource.navigate.toList(),
                preventDefault: true
            },
            {
                key: ['cmd + alt + p', 'ctrl + alt + p'],
                label: 'Pack Order Screen',
                description: 'Command + Alt + p',
                command: () => this.packOrderResource.navigate.toList(),
                preventDefault: true
            },
            {
                key: ['cmd + o', 'ctrl + o'],
                label: 'Sales Order List Screen',
                description: 'Command + o',
                command: () => this.saleOrderResource.navigate.toList(),
                preventDefault: true
            },
            {
                key: ['cmd + i', 'ctrl + i'],
                label: 'Invoice List Screen',
                description: 'Command + i',
                command: () => this.invoiceResource.navigate.toList(),
                preventDefault: true
            },
            {
                key: ['alt + i', 'alt + i'],
                label: 'Create Invoice Screen',
                description: 'Alt + i',
                command: () => this.invoiceResource.navigate.toNew(),
                preventDefault: true
            },
            {
                key: ['cmd + m', 'ctrl + m'],
                label: 'Route Monitor Screen',
                description: 'Command + m',
                command: () => this.navigateToURL('/scheduling/route-command'),
                preventDefault: true
            },
            {
                key: ['cmd + h', 'ctrl + h'],
                label: 'Route History Screen',
                description: 'Command + h',
                command: () => this.navigateToURL('/scheduling/route-history'),
                preventDefault: true
            }
        );
    }

    navigateToURL(url: string) {
        this.router.navigate([url]);
    }
}
