import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';

import {FuseSharedModule} from '@fuse/shared.module';

import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzFormModule} from 'ng-zorro-antd/form';
import {MposFormsModule} from 'app/common/mpos-forms/mpos-forms.module';
import {DifferentUserComponent} from 'app/main/public/authentication/different-user/different-user.component';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';

@NgModule({
    declarations: [DifferentUserComponent],
    imports: [
        // RouterModule.forChild(routes),
        RouterModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        NzCheckboxModule,
        NzButtonModule,
        NzFormModule,
        NzInputModule,
        FuseSharedModule,
        MposFormsModule
    ],
    providers: [
        {
            provide: TRANSLATION_BASE,
            useValue: 'differentUser'
        }
    ]
})
export class DifferentUserModule {}
