import {Component, OnInit} from '@angular/core';
import {from, zip} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {Principal} from 'app/core/auth/principal.service';
import {SERVER_API_URL} from 'app/app.constants';
import {AuthServerProvider} from 'app/core/auth/auth-jwt.service';

@Component({
    selector: 'client-portal-routemagic-assistant',
    templateUrl: './client-portal-routemagic-assistant.component.html',
    styleUrls: ['./client-portal-routemagic-assistant.component.scss']
})
export class ClientPortalRouteMagicAssistantComponent implements OnInit {
    isInitialized = false;
    loggedUser: any;
    showChatWindow = false;
    customer: any;

    private readonly AWS_REGION_FOR_CONNECT_INSTANCE = 'eu-central-1';

    private readonly CHAT_CONTAINER_ID = 'customer-chat-container';

    /**
     * Constructor
     */
    constructor(
        private _principal: Principal,
        private authProvider: AuthServerProvider
    ) {
        if (!_principal.isUserCustomer()) {
            console.warn('User is not a customer. Cannot initialize RouteMagic Assistant.');
            return;
        }
        this.customer = _principal.getCustomer();
    }

    ngOnInit(): void {}

    private doInitialization(): void {
        zip(from(import('assets/amazon-connect-chat-interface.js')))
            .pipe(
                take(1),
                tap(() => this.initCustomerChat())
            )
            .subscribe();
    }

    private initCustomerChat() {
        const connect = globalThis.connect;
        if (!connect) {
            console.error('Amazon Connect Chat Interface not loaded. Failed to initialize customer chat.');
            return;
        }
        this.configureConnectInstance(connect);
        connect.ChatInterface.init({
            containerId: this.CHAT_CONTAINER_ID // This is the id of the container where the customer chat widget will display
        });
        const httpHeaders = new Headers();
        const token = this.authProvider.getToken();
        if (!token) {
            console.error('No authentication found. Cannot initialize Amazon Connect Chat.');
            return;
        }
        httpHeaders.set('Authorization', `Bearer ${token}`);
        connect.ChatInterface.initiateChat(
            {
                name: `${this.customer.name}`,
                username: `${this.customer.name}`,
                region: this.AWS_REGION_FOR_CONNECT_INSTANCE,
                // We use RM API Endpoint to start a chat contact with the customer
                apiGatewayEndpoint: `${SERVER_API_URL}api/aws-connect/start-chat-contact/customer/${this.customer.id}`,
                headers: httpHeaders,
                featurePermissions: {
                    ATTACHMENTS: false // Disabled file attachments feature for now
                }
            },
            (_) => {
                this.isInitialized = true;
            },
            (error) => {
                console.error('initiateChat FAILED...', error);
            }
        );
    }

    private configureConnectInstance(connect: any) {
        connect.ChatSession.setGlobalConfig({
            loggerConfig: {
                logger: {
                    debug: (msg) => console.debug(msg),
                    info: (msg) => console.info(msg),
                    warn: (msg) => console.warn(msg),
                    error: (msg) => console.error(msg)
                },
                level: connect.ChatSession.LogLevel.DEBUG
            }
        });
    }

    public showChat(evt: Event) {
        this.showChatWindow = true;
        if (!this.isInitialized) {
            this.doInitialization();
        }
    }

    public closeChat(evt: Event) {
        this.showChatWindow = false;
    }
}
