<div id="login" fxLayout="column" fusePerfectScrollbar>
    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div id="login-form" *fuseIfOnDom [@animate]="{value: '*', params: {duration: '300ms', y: '100px'}}">
            <div class="logo">
                <img src="assets/images/logos/newlogo.png" />
            </div>

            <div *ngIf="authenticationError || authenticationTenantError" class="message-box error" style="margin-bottom: 16px">
                Failed to sign in! Please check your credentials and try again!
            </div>
            <div *ngIf="inactiveUser" class="message-box error" style="margin-bottom: 16px">
                This user has been deactivated. Please contact your admin.
            </div>
            <div *ngIf="deactivatedLogin" class="message-box error" style="margin-bottom: 16px">
                Tenant does not have login permission. Enable from Super Admin
            </div>
            <form name="loginForm" [formGroup]="loginForm" novalidate (submit)="login()">
                <nz-form-item [formGroup]="loginForm">
                    <nz-form-control [nzValidateStatus]="loginFormErrors.username.required ? 'error' : null" nzErrorTip="Username is required">
                        <input id="input-username" nz-input #inputElement formControlName="username" placeholder="Username" />
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item [formGroup]="loginForm">
                    <nz-form-control [nzValidateStatus]="loginFormErrors.password.required ? 'error' : null" nzErrorTip="Password is required">
                        <input id="input-password" nz-input #inputElement type="password" formControlName="password" placeholder="Password" />
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item [formGroup]="loginForm">
                    <nz-form-control
                        [nzValidateStatus]="loginFormErrors.tenant.required || isWhitespacePresentInTenantName ? 'error' : null"
                        [nzErrorTip]="getErrorToolTipCaptionForTenant()">
                        <input id="input-tenant" nz-input #inputElement formControlName="tenant" placeholder="Client ID" />
                    </nz-form-control>
                </nz-form-item>

                <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center">
                    <label id="checkbox-rememberMe" nz-checkbox formControlName="differentUser">Login as Other User</label>
                    <label id="checkbox-rememberMe" nz-checkbox formControlName="rememberMe">Remember Me</label>
                </div>

                <div fxLayout="column" fxLayoutAlign="start center">
                    <button
                        id="button-login"
                        nz-button
                        nzSize="large"
                        nzType="primary"
                        class="submit-button"
                        aria-label="LOG IN"
                        [disabled]="loginForm.invalid || isWhitespacePresentInTenantName">
                        LOGIN
                    </button>
                    <a class="forgot-password" [routerLink]="'/login-via-sso'"> Login via SSO </a>
                    <a class="forgot-password" [routerLink]="'/super-admin/login'"> Login as Super Admin? </a>
                    <a class="forgot-password" [routerLink]="'/forgot-password'"> Forgot Password? </a>
                </div>
            </form>
        </div>
    </div>
</div>
