export const locale = {
    lang: 'en',
    data: {
        NAV: {
            SERVICE_ORDERS: 'Service Orders',
            MENU: 'Menu',
            ALERTING: 'Alerting',
            ALERT: {
                TITLE: 'Alert'
            },
            BACKUPS: {TITLE: 'Backups'},
            RESTORES: {TITLE: 'Restores'},
            EXECUTIONS: {TITLE: 'Executions'},
            APPLICATIONS: 'Setup',
            EMPLOYEE_MANAGEMENT: 'Employee Management',
            ORDER_MANAGEMENT: 'Order Management',
            INVENTORY_MANAGEMENT: 'Inventory Management',
            REPORTING: 'Reports',
            IMPEX: 'Import Data',
            COMPANY_MANAGEMENT: 'Company Management',
            ROUTE_MANAGEMENT: 'Route Management',
            CUSTOMER_MANAGEMENT: 'Equipment Management',
            PRODUCT_MANAGEMENT: 'Spare Part Management',
            TASK_MANAGEMENT: 'Task Management',
            FILE_MANAGEMENT: 'File Management',
            DATA_ANALYTICS: 'Analytics',
            SURVEY_MANAGEMENT: 'Survey Management',
            AUDIT_LOG: {
                TITLE: 'Audit Log'
            },
            AUDIT: 'Audit Management',
            EQUIPMENTS: {
                TITLE: 'Equipments'
            },
            ADMIN_REINDEX: {
                TITLE: 'Admin Reindex'
            },
            USERS: {
                TITLE: 'Users'
            },
            SERVICE_ITEMS: {
                TITLE: 'Service items'
            },
            VEHICLE_INSPECTIONS: {
                TITLE: 'Vehicle Inspections'
            },
            TENANT_MANAGEMENT: {
                TITLE: 'Tenant Management'
            },
            TENANTS: {
                TITLE: 'Tenants'
            },
            PROFILES: {
                TITLE: 'Profiles'
            },
            DAILY_ACTIVITY_SUMMARYS: {
                TITLE: 'Daily Activity Summaries'
            },
            CREDIT_NOTE: {
                TITLE: 'Credit Notes'
            },
            USER_CONFIGURATION: {
                TITLE: 'Tenant Configuration'
            },
            PAYMENTS: {
                TITLE: 'Payments'
            },
            SITE_VISITS: {
                TITLE: 'Site Visits'
            },
            CASH_DEPOSITS: {
                TITLE: 'Cash Ledger'
            },
            DRIVER_DEPOSITS: {
                TITLE: 'Cash Deposits'
            },
            ROLES: {
                TITLE: 'Roles'
            },
            SAMPLE: {
                TITLE: 'Branch',
                BADGE: '25'
            },
            /*ACCOUNTING: {
                TITLE: 'Accounting'
            },*/
            WAREHOUSE: {
                TITLE: 'Warehouse'
            },
            MAJOR_ACCOUNT_CODE: {
                TITLE: 'Major Account Codes'
            },
            SUPPLIERS: {
                TITLE: 'Suppliers'
            },
            SERVICE_PRIORITY_CODE: {
                TITLE: 'Service Priority Codes'
            },
            DEPOSIT_ITEM: {
                TITLE: 'Deposit Items'
            },
            PRODUCT_IMEI_TRACK: {
                TITLE: 'IMEI Tracking'
            },
            STATEMENT_CODE: {
                TITLE: 'Statement Codes'
            },
            DUNNING_MESSAGE_CODE: {
                TITLE: 'Dunning Message Codes'
            },
            CREDIT_CLASS: {
                TITLE: 'Category 2'
            },
            CREDIT_TERM: {
                TITLE: 'Credit Terms'
            },
            DELIVERY_POINT: {
                TITLE: 'Delivery Points'
            },
            STOP_TYPE: {
                TITLE: 'Stop Types'
            },
            PAYMENT_TYPE: {
                TITLE: 'Payment Types'
            },
            CUSTOMER: {
                TITLE: 'Equipments'
            },
            CUSTOMER_GROUP: {
                TITLE: 'Equipment Groups'
            },
            PROMOTIONS: {
                TITLE: 'Promotions'
            },
            PRICE_LIST: {
                TITLE: 'Price Lists'
            },
            PRODUCT_CLASS: {
                TITLE: 'Spare Part Category 3'
            },
            PRODUCT_LIST: {
                TITLE: 'Spare Part Lists'
            },
            PRODUCT_GROUP: {
                TITLE: 'Spare Part Category 2'
            },
            UNIT_OF_MEASURE: {
                TITLE: 'Unit of Measure'
            },
            PACKAGING: {
                TITLE: 'Packaging'
            },
            PRODUCT_TAX_CATEGORY: {
                TITLE: 'Product Tax Categories'
            },
            PRODUCT: {
                TITLE: 'Spare Parts'
            },
            DELIVERY_PACKAGE_TYPE: {
                TITLE: 'Delivery Package Types'
            },
            VEHICLE: {
                TITLE: 'Vehicles'
            },
            VEHICLE_TYPE: {
                TITLE: 'Vehicle Types'
            },
            TRAILER: {
                TITLE: 'Trailers'
            },
            TRAILER_TYPE: {
                TITLE: 'Trailer Types'
            },
            CORE_REPORTS: {
                TITLE: 'Core Reports'
            },
            TEMPERATURE_REPORTS: {
                TITLE: 'Temperature Reports'
            },
            ROUTE_TYPE: {
                TITLE: 'Route Types'
            },
            ROUTE: {
                TITLE: 'Routes'
            },
            ORDER: {
                TITLE: 'Orders'
            },
            SALE_ORDER: {
                TITLE: 'Service Orders'
            },
            ORDER_REJECTION_CODE: {
                TITLE: 'Order Rejection Codes'
            },
            INCOMING_INVENTORY: {
                TITLE: 'Receive / Adjust Inventory'
            },
            LOAD_ORDER: {
                TITLE: 'Load Orders'
            },
            REPORTS: {
                TITLE: 'Reports'
            },
            PICK_ORDER: {
                TITLE: 'Pick Orders'
            },
            PACK_ORDER: {
                TITLE: 'Pack Orders'
            },
            DELIVERY_ORDER: {
                TITLE: 'Delivery Orders'
            },
            CUSTOMER_SMS_TEXT: {
                TITLE: 'Equipment SMS Texts'
            },
            TERRITORIES: {
                TITLE: 'Territories'
            },
            EMPLOYEE: {
                TITLE: 'Employees'
            },
            EMPLOYEE_GROUP: {
                TITLE: 'Employee Groups'
            },
            HANDHELD_PROFILE: {
                TITLE: 'Handheld Profiles'
            },
            IMPORT_DATA: {
                TITLE: 'Import Data'
            },
            ROUTE_DISPATCH: {
                TITLE: 'Route Scheduler'
            },
            ROUTE_PLANNER: {
                TITLE: 'Route Planner'
            },
            ROUTE_COMMAND: {
                TITLE: 'Route Monitor'
            },
            ROUTE_HISTORY: {
                TITLE: 'Route History'
            },
            ROUTE_SCHEDULES: {
                TITLE: 'Route Schedules'
            },
            INVENTORY_STOCK: {
                TITLE: 'Inventory Stock'
            },
            VEHICLE_UNLOADS: {
                TITLE: 'Vehicle Unloads'
            },
            COMPANY: {
                TITLE: 'Company'
            },
            REPORT: {
                TITLE: 'All Reports'
            },
            INVOICE: {
                TITLE: 'Invoices'
            },
            HAULIER_ORDER: {
                TITLE: 'Haulier Orders'
            },
            ORGANISATION: {
                TITLE: 'Organisations'
            },
            STOCK_TAKES: {
                TITLE: 'Stock Takes'
            },
            WAREHOUSE_TRANSFER: {
                TITLE: 'Warehouse Transfer'
            },
            PURCHASE_ORDERS: {
                TITLE: 'Purchase Orders'
            },
            PURCHASE_INVOICE: {
                TITLE: 'Purchase Invoice'
            },
            VEHICLE_CHECK_LIST: {
                TITLE: 'Safety Profiles'
            },
            INVENTORY_TRANSACTIONS: {
                TITLE: 'Inventory Ledger'
            },
            RECIPES: {
                TITLE: 'Recipes'
            },
            PRODUCTION_VOUCHERS: {
                TITLE: 'Production Vouchers'
            },
            CUSTOMER_TRANSACTIONS: {
                TITLE: 'Customer Balance Ledger'
            },
            DRIVER_ACCESS_CODES: {
                TITLE: 'Access Codes'
            },
            CUSTOMER_AGING_REPORTS: {
                TITLE: 'Customer Aging Reports'
            },
            CASH_JOURNAL_REPORTS: {
                TITLE: 'Cash Journal Reports'
            },
            SHORTFALL_REPORT: {
                TITLE: 'Shortfall Reports'
            },
            APP_REQUEST: {
                TITLE: 'App Requests'
            },
            SURVEY: {
                TITLE: 'Surveys'
            },
            EMPLOYEE_TARGET: {
                TITLE: 'Employee Targets'
            },
            ROUTE_TARGET: {
                TITLE: 'Route Targets'
            },
            REASON_CODE: {
                TITLE: 'Reason Codes'
            },
            PRODUCT_COST_CHANGE: {
                TITLE: 'Product Cost Changes'
            },
            STOCK_CHANGE_CONDITION: {
                TITLE: 'Stock Condition Change'
            },
            DEMAND_REPORT: {
                TITLE: 'Demand Report'
            },
            EOD_SURVEY: {
                TITLE: 'EOD Survey'
            },
            MAIL_AUDIT: {
                TITLE: 'Mail Audits'
            },
            WORKSHOP: {
                TITLE: 'Workshop'
            },
            RECIPE_EXECUTION_REPORT: {
                TITLE: 'Recipe Execution Report'
            },
            SUPER_ADMIN: {
                TITLE: 'SUPER ADMIN'
            },
            STANDING_SALE_ORDERS: {
                TITLE: 'Standing Sale Orders'
            },
            STANDING_LOAD_ORDERS: {
                TITLE: 'Standing Load Orders'
            },
            EOD_INVENTORY_REPORT: {
                TITLE: 'EOD Inventory Report'
            },
            SUPPLIER_PAYMENT_TERM: {
                TITLE: 'Supplier Payment Terms'
            },
            SUPPLIER_GROUPS: {
                TITLE: 'Supplier Groups'
            }
        }
    }
};
