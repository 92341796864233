import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzFormModule} from 'ng-zorro-antd/form';

import {FuseSharedModule} from '@fuse/shared.module';
import {ResetPasswordComponent} from 'app/main/public/authentication/reset-password/reset-password.component';

const routes = [
    {
        path: 'auth/reset-password',
        component: ResetPasswordComponent
    }
];

@NgModule({
    declarations: [ResetPasswordComponent],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        NzCheckboxModule,
        NzButtonModule,
        NzFormModule,
        NzInputModule,
        FuseSharedModule
    ]
})
export class ResetPasswordModule {}
