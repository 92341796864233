import {EMPTY, Observable} from 'rxjs';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {SERVER_API_URL} from 'app/app.constants';
import {StringUtils} from 'app/blocks/util/string-util';
import {SwCacheService} from 'app/common/sw-cache/sw-cache.service';
import {Injectable} from '@angular/core';

export const AUTH_NOT_REQUIRED_URIS = [
    '/api/super-admin/authenticate',
    '/api/register',
    '/api/invitation-create-account',
    '/api/invitation-register-customer',
    '/api/public/countries',
    '/api/activate',
    '/api/authenticate',
    '/api/account/reset-password/init',
    '/api/account/reset-password/finish',
    '/api/payments/callback',
    '/management/health',
    '/management/info',
    '/swagger-resources/configuration/ui'
];

export const AUTH_NOT_REQUIRED_URI_REGEX = [String.raw`/api/saml2/(.*)`];

export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private localStorage: LocalStorageService,
        private sessionStorage: SessionStorageService,
        private swCacheService: SwCacheService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request || !request.url || (/^http/.test(request.url) && !(SERVER_API_URL && request.url.startsWith(SERVER_API_URL)))) {
            return next.handle(request);
        }

        const token = this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken');

        // Check if URL ends with any of the suffixes that require authentication
        const isAuthRequired = this.isAuthRequiredForRequest(request);
        if (isAuthRequired && StringUtils.isEmpty(token)) {
            console.log(`Request: ${request.url} is ignored since token is not set`);
            throw new Error(`Authentication token is missing for url: ${request.url}`);
        }

        if (token) {
            const headers = {Authorization: 'Bearer ' + token};
            if (!this.swCacheService?.isCacheEnabled?.()) {
                headers['ngsw-bypass'] = 'true';
            }
            request = request.clone({
                setHeaders: headers
            });
        }
        return next.handle(request);
    }

    private isAuthRequiredForRequest(request: HttpRequest<any>): boolean {
        if (!request || !request.url) {
            return true;
        }
        return !(
            AUTH_NOT_REQUIRED_URIS.some((suffix) => request.url.endsWith(suffix)) ||
            AUTH_NOT_REQUIRED_URI_REGEX.some((regex) => new RegExp(regex).test(request.url))
        );
    }
}
