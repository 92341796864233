import {Component, Inject, OnDestroy, OnInit, ChangeDetectorRef} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Platform} from '@angular/cdk/platform';
import {TranslateService} from '@ngx-translate/core';
import {superAdminNavigation} from 'app/navigation/super-admin-navigation';
import {RoleService} from 'app/blocks/service/api/role.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Store} from '@ngxs/store';

import {FuseConfigService} from '@fuse/services/config.service';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {FuseSplashScreenService} from '@fuse/services/splash-screen.service';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';

import {navigation} from 'app/navigation/navigation';
import {adminNavigation} from 'app/navigation/admin-navigation';
import {customerNavigation} from 'app/navigation/customer-navigation';
import {locale as navigationEnglish} from 'app/navigation/i18n/en';
import {locale as navigationFrench} from 'app/navigation/i18n/fr';
import {locale as navigationIndian} from 'app/navigation/i18n/in';
import {locale as service_order_english} from 'app/navigation/i18n/en-service_order';
import {Principal} from 'app/core/auth/principal.service';
import {RESPONSIVE_SCREEN_SIZE} from 'app/app.constants';
import {HostListener} from '@angular/core';
import {SettingsService} from 'app/common/settings.service';
import {ENABLE_SERVICE_ORDER_ON_SALE_ORDER_PAGE} from 'app/constants/setting';
import {LogService} from 'app/blocks/service/log.service';
import {ServiceWorkerUpdateService} from 'app/common/service-worker-update.service';
import {SwCacheService} from 'app/common/sw-cache/sw-cache.service';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    adminNavigation: any;
    superAdminNavigation: any;
    customerNavigation: any;
    coreReport: any;
    enableServiceOrderOnSaleOrderPage = false;

    // Private
    private _unsubscribeAll: Subject<void>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     * @param _principal
     * @param _store
     * @param changeDetectorRef
     * @param roleService
     * @param settingsService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private _principal: Principal,
        private _store: Store,
        private changeDetectorRef: ChangeDetectorRef,
        private roleService: RoleService,
        private settingsService: SettingsService,
        private logService: LogService,
        private serviceWorkerUpdates: ServiceWorkerUpdateService,
        private swCacheService: SwCacheService
    ) {
        // Get default navigation
        this.navigation = navigation;
        this.adminNavigation = adminNavigation;
        this.superAdminNavigation = superAdminNavigation;
        this.customerNavigation = customerNavigation;
        this.coreReport = this.customerNavigation[0].children.find((ch) => ch.id === 'core-reports');

        // Register the navigation to the service
        this._fuseNavigationService.register('admin', this.adminNavigation);
        this._fuseNavigationService.register('super-admin', this.superAdminNavigation);
        this._fuseNavigationService.register('main', this.navigation);
        this._fuseNavigationService.register('customer', this.customerNavigation);

        // Register service worker update logging
        this.serviceWorkerUpdates.logServiceWorkerUpdates();

        // Set the navigation depending upon the user's role
        this._principal.getAuthenticationState().subscribe((userIdentity) => {
            if (userIdentity) {
                if (userIdentity.authorities && userIdentity.authorities.length > 0 && userIdentity.authorities.indexOf('ROLE_SUPER_ADMIN') > -1) {
                    this._fuseNavigationService.setCurrentNavigation('super-admin');
                } else if (userIdentity.authorities && userIdentity.authorities.length > 0 && userIdentity.authorities.indexOf('ROLE_ADMIN') > -1) {
                    this._fuseNavigationService.setCurrentNavigation('admin');
                    this.roleService.updateNavigation();
                } else if (
                    userIdentity.authorities &&
                    userIdentity.authorities.length > 0 &&
                    userIdentity.authorities.indexOf('ROLE_CUSTOMER') > -1
                ) {
                    this._fuseNavigationService.setCurrentNavigation('customer');
                    this.handleResponsive();
                    this.roleService.updateNavigation();
                } else {
                    this._fuseNavigationService.setCurrentNavigation('main');
                    this.roleService.updateNavigation();
                }
            }
        });

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        //set languages
        this.setTranslationsAndLanguage();

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    setTranslationsAndLanguage(): void {
        // Add languages
        this._translateService.addLangs(['en', 'tr', 'in']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(
            this.enableServiceOrderOnSaleOrderPage ? service_order_english : navigationEnglish,
            navigationFrench,
            navigationIndian
        );

        // Use a language
        this._translateService.use('en');
    }

    initCompanySettings(): void {
        this.settingsService.subscribeToSetting(ENABLE_SERVICE_ORDER_ON_SALE_ORDER_PAGE, (value) => {
            this.enableServiceOrderOnSaleOrderPage = value;
            if (this.enableServiceOrderOnSaleOrderPage) {
                //update the captions: initialise the languages & translation requirements again if the setting value is true
                // to update the en.ts with en-service_order.ts file
                this.setTranslationsAndLanguage();
            }
        });

        this.swCacheService.subscribeToCacheSettings();
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.initCompanySettings();
        // Subscribe to config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
            this.fuseConfig = config;

            if (this.fuseConfig.layout.width === 'boxed') {
                this.document.body.classList.add('boxed');
            } else {
                this.document.body.classList.remove('boxed');
            }
        });
        this.handleResponsive();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event?): void {
        this.handleResponsive();
        this.changeDetectorRef.detectChanges();
    }

    private handleResponsive(): void {
        if (this._fuseNavigationService.getCurrentNavigation()) {
            if (window.innerWidth <= RESPONSIVE_SCREEN_SIZE && this.coreReport) {
                this._fuseNavigationService.updateNavigationItem(this.coreReport.id, {hidden: true});
            } else {
                this._fuseNavigationService.updateNavigationItem(this.coreReport.id, {hidden: false});
            }
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
}
