import {Injectable} from '@angular/core';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';
import {locale as english} from 'app/common/i18n/en';
import {locale as french} from 'app/common/i18n/fr';

@Injectable({
    providedIn: 'root'
})
export class AppInitializer {
    constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
        this._fuseTranslationLoaderService.loadTranslations(english, french);
    }
}
