import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class MfaService {
    constructor(private http: HttpClient) {}

    public isMfaEnabled(): Promise<HttpResponse<any>> {
        return this.http
            .post<any>(
                `${SERVER_API_URL}api/account/mfa-is-enabled`,
                {},
                {
                    observe: 'response'
                }
            )
            .toPromise();
    }

    public isMfaVerified(mfaCode: string): Promise<HttpResponse<any>> {
        return this.http
            .post<any>(
                `${SERVER_API_URL}api/account/mfa-is-verified`,
                {
                    mfaCode: mfaCode
                },
                {
                    observe: 'response'
                }
            )
            .toPromise();
    }
}
