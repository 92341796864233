<div id="forgot-password" fxLayout="column">
    <div id="forgot-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div id="forgot-password-form" [@animate]="{value: '*', params: {duration: '300ms', y: '100px'}}">
            <div class="logo">
                <img src="assets/images/logos/newlogo.png" />
            </div>

            <div class="title">RECOVER YOUR PASSWORD</div>

            <form name="forgotPasswordForm" [formGroup]="forgotPasswordForm" novalidate>
                <nz-form-item [formGroup]="forgotPasswordForm">
                    <nz-form-control
                        [nzValidateStatus]="
                            forgotPasswordForm.get('email').touched && forgotPasswordForm.get('email').hasError('required') ? 'error' : null
                        "
                        nzErrorTip="Email is required">
                        <input nz-input #inputElement formControlName="email" placeholder="Email" />
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item [formGroup]="forgotPasswordForm">
                    <nz-form-control
                        [nzValidateStatus]="
                            forgotPasswordForm.get('tenant').touched && forgotPasswordForm.get('tenant').hasError('required') ? 'error' : null
                        "
                        nzErrorTip="Client ID is required">
                        <input nz-input #inputElement formControlName="tenant" placeholder="Tenant" />
                    </nz-form-control>
                </nz-form-item>

                <button
                    nz-button
                    nzSize="large"
                    nzType="primary"
                    class="submit-button"
                    aria-label="SEND RESET LINK"
                    [disabled]="forgotPasswordForm.invalid"
                    (click)="sendActivationLink()">
                    SEND RESET LINK
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/login'">Go back to login</a>
            </div>
        </div>
    </div>
</div>
