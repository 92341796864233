export const locale = {
    lang: 'in',
    data: {
        NAV: {
            MENU: 'Menu',
            APPLICATIONS: 'Setup',
            EMPLOYEE_MANAGEMENT: 'Employee Management',
            ORDER_MANAGEMENT: 'Order Management',
            INVENTORY_MANAGEMENT: 'Inventory Management',
            REPORTING: 'Reports',
            IMPEX: 'Import Data',
            ALERTING: 'Alerting',
            ALERT: {
                TITLE: 'Alert'
            },
            COMPANY_MANAGEMENT: 'Company Management',
            ROUTE_MANAGEMENT: 'Partner Management',
            CUSTOMER_MANAGEMENT: 'Customer Management',
            PRODUCT_MANAGEMENT: 'Product Management',
            TASK_MANAGEMENT: 'Task Management',
            FILE_MANAGEMENT: 'File Management',
            DATA_ANALYTICS: 'Analytics',
            SURVEY_MANAGEMENT: 'Survey Management',
            BACKUPS: {TITLE: 'Backups'},
            RESTORES: {TITLE: 'Restores'},
            EXECUTIONS: {TITLE: 'Executions'},
            AUDIT_LOG: {
                TITLE: 'Audit Log'
            },
            VEHICLE_INSPECTIONS: {
                TITLE: 'Vehicle Inspections'
            },
            TENANT_MANAGEMENT: {
                TITLE: 'Tenant Management'
            },
            TENANTS: {
                TITLE: 'Tenants'
            },
            PAYMENTS: {
                TITLE: 'Payments'
            },
            ROLES: {
                TITLE: 'Roles'
            },
            SITE_VISITS: {
                TITLE: 'Site Visits'
            },
            CASH_DEPOSITS: {
                TITLE: 'Cash Ledger'
            },
            DRIVER_DEPOSITS: {
                TITLE: 'Cash Deposits'
            },
            SAMPLE: {
                TITLE: 'Branch',
                BADGE: '25'
            },
            /*ACCOUNTING: {
                TITLE: 'Accounting'
            },*/
            WAREHOUSE: {
                TITLE: 'Warehouse'
            },
            MAJOR_ACCOUNT_CODE: {
                TITLE: 'Major Account Codes'
            },
            SUPPLIERS: {
                TITLE: 'Suppliers'
            },
            SERVICE_PRIORITY_CODE: {
                TITLE: 'Service Priority Codes'
            },
            DEPOSIT_ITEM: {
                TITLE: 'Deposit Items'
            },
            PRODUCT_IMEI_TRACK: {
                TITLE: 'IMEI Tracking'
            },
            STATEMENT_CODE: {
                TITLE: 'Statement Codes'
            },
            DUNNING_MESSAGE_CODE: {
                TITLE: 'Dunning Message Codes'
            },
            CREDIT_CLASS: {
                TITLE: 'Credit Class'
            },
            CREDIT_TERM: {
                TITLE: 'Credit Terms'
            },
            DELIVERY_POINT: {
                TITLE: 'Delivery Points'
            },
            STOP_TYPE: {
                TITLE: 'Stop Types'
            },
            PAYMENT_TYPE: {
                TITLE: 'Payment Types'
            },
            CUSTOMER: {
                TITLE: 'Customers'
            },
            CUSTOMER_GROUP: {
                TITLE: 'Customer Groups'
            },
            PROMOTIONS: {
                TITLE: 'Promotions'
            },
            PRICE_LIST: {
                TITLE: 'Price Lists'
            },
            PRODUCT_CLASS: {
                TITLE: 'Product Class'
            },
            PRODUCT_LIST: {
                TITLE: 'Product Lists'
            },
            PRODUCT_GROUP: {
                TITLE: 'Product Group'
            },
            UNIT_OF_MEASURE: {
                TITLE: 'Unit of Measure'
            },
            PACKAGING: {
                TITLE: 'Packaging'
            },
            PRODUCT_TAX_CATEGORY: {
                TITLE: 'Product Tax Categories'
            },
            PRODUCT: {
                TITLE: 'Products'
            },
            DELIVERY_PACKAGE_TYPE: {
                TITLE: 'Delivery Package Types'
            },
            VEHICLE: {
                TITLE: 'Vehicles'
            },
            VEHICLE_TYPE: {
                TITLE: 'Vehicle Types'
            },
            TRAILER: {
                TITLE: 'Trailers'
            },
            TRAILER_TYPE: {
                TITLE: 'Trailer Types'
            },
            CORE_REPORTS: {
                TITLE: 'Core Reports'
            },
            TEMPERATURE_REPORTS: {
                TITLE: 'Temperature Reports'
            },
            ROUTE_TYPE: {
                TITLE: 'Partner Types'
            },
            ROUTE: {
                TITLE: 'Partners'
            },
            ORDER: {
                TITLE: 'Orders'
            },
            SALE_ORDER: {
                TITLE: 'Sale Orders'
            },
            ORDER_REJECTION_CODE: {
                TITLE: 'Order Rejection Codes'
            },
            INCOMING_INVENTORY: {
                TITLE: 'Receive / Adjust Inventory'
            },
            LOAD_ORDER: {
                TITLE: 'Consignments'
            },
            REPORTS: {
                TITLE: 'Reports'
            },
            PICK_ORDER: {
                TITLE: 'Pick Orders'
            },
            PACK_ORDER: {
                TITLE: 'Pack Orders'
            },
            DELIVERY_ORDER: {
                TITLE: 'Delivery Orders'
            },
            CUSTOMER_SMS_TEXT: {
                TITLE: 'Customer SMS Texts'
            },
            TERRITORIES: {
                TITLE: 'Territories'
            },
            EMPLOYEE: {
                TITLE: 'Employees'
            },
            EMPLOYEE_GROUP: {
                TITLE: 'Employee Groups'
            },
            HANDHELD_PROFILE: {
                TITLE: 'Handheld Profiles'
            },
            IMPORT_DATA: {
                TITLE: 'Import Data'
            },
            ROUTE_DISPATCH: {
                TITLE: 'Partner Scheduler'
            },
            ROUTE_PLANNER: {
                TITLE: 'Partner Planner'
            },
            ROUTE_COMMAND: {
                TITLE: 'Partner Monitor'
            },
            ROUTE_HISTORY: {
                TITLE: 'Route History'
            },
            ROUTE_SCHEDULES: {
                TITLE: 'Route Schedules'
            },
            INVENTORY_STOCK: {
                TITLE: 'Inventory Stock'
            },
            VEHICLE_UNLOADS: {
                TITLE: 'Vehicle Unloads'
            },
            COMPANY: {
                TITLE: 'Company'
            },
            REPORT: {
                TITLE: 'All Reports'
            },
            INVOICE: {
                TITLE: 'Invoices'
            },
            HAULIER_ORDER: {
                TITLE: 'Haulier Orders'
            },
            ORGANISATION: {
                TITLE: 'Organisations'
            },
            STOCK_TAKES: {
                TITLE: 'Stock Takes'
            },
            WAREHOUSE_TRANSFER: {
                TITLE: 'Warehouse Transfer'
            },
            VEHICLE_CHECK_LIST: {
                TITLE: 'Safety Profiles'
            },
            INVENTORY_TRANSACTIONS: {
                TITLE: 'Inventory Ledger'
            },
            RECIPES: {
                TITLE: 'Recipes'
            },
            PRODUCTION_VOUCHERS: {
                TITLE: 'Production Vouchers'
            },
            CUSTOMER_TRANSACTIONS: {
                TITLE: 'Customer Balance Ledger'
            },
            DRIVER_ACCESS_CODES: {
                TITLE: 'in Access Codes'
            },
            SHORTFALL_REPORT: {
                TITLE: 'Shortfall Reports'
            },
            APP_REQUEST: {
                TITLE: 'App Requests'
            },
            SURVEY: {
                TITLE: 'Surveys'
            },
            EMPLOYEE_TARGET: {
                TITLE: 'Employee Targets'
            },
            ROUTE_TARGET: {
                TITLE: 'Route Targets'
            },
            REASON_CODE: {
                TITLE: 'Reason Codes'
            },
            PRODUCT_COST_CHANGE: {
                TITLE: 'Product Cost Changes'
            },
            STOCK_CHANGE_CONDITION: {
                TITLE: 'Stock Condition Change'
            },
            DEMAND_REPORT: {
                TITLE: 'Demand Report'
            },
            EOD_SURVEY: {
                TITLE: 'EOD Survey'
            },
            WORKSHOP: {
                TITLE: 'Workshop'
            },
            RECIPE_EXECUTION_REPORT: {
                TITLE: 'Recipe Execution Report'
            },
            SUPER_ADMIN: {
                TITLE: 'SUPER ADMIN'
            },
            PROFILES: {
                TITLE: 'Profiles'
            },
            EOD_INVENTORY_REPORT: {
                TITLE: 'EOD Inventory Report'
            },
            DEFAULT_PRODUCT_PRICE_CHANGE: {
                TITLE: 'Default Price Change'
            },
            PURCHASE_PAYMENTS: {
                TITLE: 'Purchase Payments'
            },
            PURCHASE_CREDIT_NOTE: {
                TITLE: 'Purchase Debit Notes'
            },
            SUPPLIER_PAYMENT_TERM: {
                TITLE: 'Supplier Payment Terms'
            },
            INTEGRATION_MAPPING: {
                TITLE: 'Integration Mapping'
            },
            SUPPLIER_TRANSACTIONS: {
                TITLE: 'Supplier Ledger'
            },
            SUPPLIER_GROUPS: {
                TITLE: 'Supplier Groups'
            }
        }
    }
};
