import {Router, ActivatedRoute} from '@angular/router';
import {StateStorageService} from 'app/core/auth/state-storage.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {LoginService} from 'app/core/login/login.service';

@Component({
    selector: 'create-account',
    templateUrl: './create-account.component.html',
    styleUrls: ['./create-account.component.scss'],
    animations: fuseAnimations
})
export class CreateAccountComponent implements OnInit, OnDestroy {
    accountForm: UntypedFormGroup;

    accountFormErrors: any;
    authenticationError = false;
    invalidInviteCode = false;
    authenticationTenantError = false;

    credentials: any;
    accountCreated = false;

    // Private
    private _unsubscribeAll: Subject<void>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {UntypedFormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: UntypedFormBuilder,
        private loginService: LoginService,
        private stateStorageService: StateStorageService,
        private _route: ActivatedRoute,
        // private elementRef: ElementRef,
        // private renderer: Renderer,
        private router: Router
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                }
            }
        };

        // Set the defaults
        this.accountFormErrors = {
            email: {},
            password: {},
            confirmPassword: {},
            invitationCode: {},
            tenant: {}
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.accountForm = this._formBuilder.group({
            email: ['', [Validators.required]],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required],
            invitationCode: ['', Validators.required],
            tenant: ['', Validators.required]
        });

        const queryParams = this._route.snapshot.queryParams;
        if (queryParams && queryParams.inviteCode && queryParams.tenant) {
            this.accountForm.patchValue({
                invitationCode: queryParams.inviteCode,
                tenant: queryParams.tenant
            });
        } else if (!queryParams.inviteCode) {
            this.invalidInviteCode = true;
        }

        this.accountForm.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            this.onLoginFormValuesChanged();
        });
    }

    createAccount(): void {
        this.loginService
            .createAccount(this.accountForm.value)
            .then(() => {
                this.authenticationTenantError = false;
                this.authenticationError = false;
                this.accountCreated = true;

                this.accountForm.disable();
            })
            .catch((e) => {
                this.authenticationTenantError = false;
                this.authenticationError = true;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On form values changed
     */
    onLoginFormValuesChanged(): void {
        for (const field in this.accountFormErrors) {
            if (!this.accountFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.accountFormErrors[field] = {};

            // Get the control
            const control = this.accountForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.accountFormErrors[field] = control.errors;
            }
        }
    }
}
