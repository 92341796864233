import {ModuleWithProviders, NgModule} from '@angular/core';
import {MposConfirmDialogComponent} from 'app/common/dialog/confirm-dialog/confirm-dialog.component';
import {MposMessageDialogComponent} from 'app/common/dialog/message-dialog/message-dialog.component';
import {DialogService} from 'app/common/dialog/dialog-service';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzButtonModule} from 'ng-zorro-antd/button';

@NgModule({
    declarations: [MposConfirmDialogComponent, MposMessageDialogComponent],
    imports: [NzModalModule, NzButtonModule]
})
export class DialogModule {
    static forRoot(): ModuleWithProviders<DialogModule> {
        return {
            ngModule: DialogModule,
            providers: [DialogService]
        };
    }
}
