import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from 'app/core/auth/account.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    resetPasswordForm: UntypedFormGroup;
    key: string;
    tenant: string;

    // Private
    private _unsubscribeAll: Subject<void>;

    constructor(
        private router: Router,
        private accountService: AccountService,
        private _toastr: ToastrService,
        private activeRoute: ActivatedRoute,
        private _formBuilder: UntypedFormBuilder,
        private _fuseConfigService: FuseConfigService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.getRouteParams();
        this.resetPasswordForm = this._formBuilder.group({
            name: [''],
            email: ['', [Validators.email]],
            password: ['', Validators.required],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
        });

        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.resetPasswordForm
            .get('password')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.resetPasswordForm.get('passwordConfirm').updateValueAndValidity();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // tslint:disable-next-line:typedef
    getRouteParams() {
        const queryParams = this.activeRoute.snapshot.queryParams;
        this.key = queryParams.key;
        this.tenant = queryParams.tenant;
        console.log(queryParams.key);
        console.log(queryParams.tenant);
    }

    /**
     * Reset user's password
     */
    // tslint:disable-next-line:typedef
    resetPassword() {
        this.accountService.finishPasswordReset(this.tenant, this.key, this.resetPasswordForm.value.password).subscribe(
            (result) => {
                console.log(result);
                this._toastr.info('Password was successfully changed');
            },
            (err) => {
                console.log(err);
                this._toastr.error('The operation couldnt be completed successfully');
            }
        );
        this.router.navigate(['/login']);
    }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return null;
    }

    if (passwordConfirm.value === '') {
        return null;
    }

    if (password.value === passwordConfirm.value) {
        return null;
    }

    return {passwordsNotMatching: true};
};
