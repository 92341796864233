import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {HttpClient} from '@angular/common/http';
import {AuthServerProvider} from 'app/core/auth/auth-jwt.service';

@Component({
    selector: 'login-via-sso',
    templateUrl: './login-via-sso.component.html',
    styleUrls: ['./login-via-sso.component.scss'],
    animations: fuseAnimations
})
export class LoginViaSSOComponent implements OnInit, OnDestroy {
    loginForm: UntypedFormGroup;
    isWhitespacePresentInTenantName = false;
    // Private
    private _unsubscribeAll: Subject<void>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {UntypedFormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: UntypedFormBuilder,
        private authService: AuthServerProvider
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            tenant: ['', Validators.required]
        });

        this.loginForm.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            this.onLoginFormValuesChanged();
        });
    }

    login(): void {
        const tenant = this.loginForm.get('tenant').value;
        this.authService.getSSOAuthenticationUrl(tenant).subscribe((url) => {
            // Redirect window to the SSO URL for SSO Flow
            window.location.href = url;
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On form values changed
     */
    onLoginFormValuesChanged(): void {
        const tenantName = this.loginForm.get('tenant').value;
        if (tenantName != null && tenantName.indexOf(' ') >= 0) {
            this.isWhitespacePresentInTenantName = true;
        } else {
            this.isWhitespacePresentInTenantName = false;
        }
    }

    getErrorToolTipCaptionForTenant(): string {
        return this.isWhitespacePresentInTenantName ? 'Client ID cannot have space' : 'Client ID is required';
    }
}
