import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {SuperUserService} from 'app/core/auth/super-user.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';

import {FuseConfigService} from '@fuse/services/config.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {LoginService} from 'app/core/login/login.service';
import {ASYNC_TASKS, LOGIN_API_URL} from 'app/app.constants';
import {Principal} from 'app/core/auth/principal.service';

import {navigation} from 'app/navigation/navigation';
import {Router} from '@angular/router';
import {faAngleDown, faSignOutAlt, faBars, faSyncAlt} from '@fortawesome/free-solid-svg-icons';
import {faQuestionCircle} from '@fortawesome/free-regular-svg-icons';
import {CompanyService} from 'app/blocks/service/api/company.service';
import {AccountService} from 'app/core/auth/account.service';
import {IUser} from 'app/core/user/user.model';
import {DomSanitizer} from '@angular/platform-browser';
import versionJson from '../../../../../version.json';
import {Store} from '@ngxs/store';
import {TrackAsyncRequest} from 'app/blocks/store/actions/common-actions';
import {AlertService} from 'app/common/alert/alert.service';
import {SwCacheService} from 'app/common/sw-cache/sw-cache.service';
import {SettingsService} from 'app/common/settings.service';
import {CACHE_ENABLED} from 'app/constants/setting';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar-ant.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {
    // Added by RouteMagic
    faAngleDown = faAngleDown;
    faSignOutAlt = faSignOutAlt;
    faQuestionCircle = faQuestionCircle;
    faMenu = faBars;
    faSyncAlt = faSyncAlt;
    // End
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any = {
        id: 'en',
        title: 'English',
        flag: 'uk'
    };
    userStatusOptions: any[];
    name = '';
    companyName: string;

    logoSrc: any = null;
    logoWidth: number = null;
    logoHeight: number = null;
    tenant = '';

    // Private
    private _unsubscribeAll: Subject<void>;

    isSuperuser = false;
    appVersionNo = '';
    bulkProgress$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    cacheEnabled = false;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _principal: Principal,
        private _loginService: LoginService,
        private _router: Router,
        private _companyService: CompanyService,
        private _accountService: AccountService,
        public _domSanitizer: DomSanitizer,
        private superUserService: SuperUserService,
        private _store: Store,
        private _alertService: AlertService,
        private swCacheService: SwCacheService,
        private _settingsService: SettingsService
    ) {
        this.isSuperuser = this.superUserService.getIsSuperUser();
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#b02669'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'uk'
            },
            {
                id: 'fr',
                title: 'French',
                flag: 'fr'
            },
            {
                id: 'in',
                title: 'Indian',
                flag: 'in'
            },
            {
                id: 'us',
                title: 'English (United States)',
                flag: 'us'
            }
        ];

        this.navigation = navigation;
        this.appVersionNo = versionJson.appVersion;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((settings) => {
            this.horizontalNavbar = settings.layout.navbar.position === 'top';
            this.rightNavbar = settings.layout.navbar.position === 'right';
            this.hiddenNavbar = settings.layout.navbar.hidden === true;
        });

        this._store
            .select((state) => state.common[ASYNC_TASKS])
            .subscribe((asyncTasks: any) => {
                if (asyncTasks) {
                    this.bulkProgress$.next(asyncTasks);
                }
            });

        this._principal.getAuthenticationState().subscribe((userIdentity) => {
            if (userIdentity) {
                if (userIdentity.customer != null) {
                    // Client Portal
                    this.name = userIdentity.customer.name;
                } else {
                    this.name = userIdentity.firstName + ' ' + userIdentity.lastName;
                }
                this.tenant = userIdentity.tenantId;
                this._store.dispatch(new TrackAsyncRequest());
            }
        });

        this._principal
            .getAuthenticationState()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user) => {
                if (this._principal.isAuthenticated()) {
                    this.setLanguageForUser();
                    if (!this.isSuperuser) {
                        this.getCompanyDetails();
                        this.getLogo();
                    }
                }
            });

        this._settingsService.subscribeToSetting(CACHE_ENABLED, (value) => {
            this.cacheEnabled = !!value;
        });
    }

    setLanguageForUser(): void {
        // Set the selected language from default languages
        this._accountService
            .get()
            .pipe()
            .subscribe((response) => {
                if (response) {
                    const empUser = response.body as IUser;
                    if (empUser && empUser.langKey) {
                        this.selectedLanguage = _.find(this.languages, {
                            id: empUser.langKey
                        });

                        // Note for reviewer: This was the only workaround I could find, the language needs to be set after the page has loaded.
                        // Also tried other lifecycle hooks but no luck, this seems to be the best way to update the language otherwise it will not update.
                        setTimeout(() => {
                            this.setLanguage(this.selectedLanguage);
                        }, 1000);
                    }
                }
            });
    }

    getCompanyDetails(): void {
        this._companyService.getCompany().then((data) => {
            if (data && data.name) {
                this.companyName = data.name;
            }
        });
    }

    getLogo(): void {
        this._companyService.getLogo().then((data) => {
            const reader = new FileReader();
            const image = new Image();
            image.onload = () => {
                this.logoWidth = 80;
                this.logoHeight = 60;
            };
            reader.addEventListener(
                'load',
                () => {
                    image.src = reader.result as string;
                    this.logoSrc = reader.result;
                },
                false
            );
            if (data && data.size !== 0) {
                reader.readAsDataURL(data);
            }
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang, updateAccount?: boolean): void {
        if (this.isSuperuser) {
            return;
        }
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;
        // Update language key
        if (updateAccount) {
            this._accountService.updateLanguageKey(lang.id).pipe().subscribe();
        }
        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    logout(): void {
        this._loginService.logout();
        this._router.navigate([LOGIN_API_URL]);
    }

    async resetSwCacheBtn(): Promise<void> {
        await this.swCacheService.resetSwCache();
        this._alertService.showSuccess('Cache cleared successfully, reloading');
        window.location.reload();
    }
}
