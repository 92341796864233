<div id="reset-password" fxLayout="column">
    <div id="reset-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div id="reset-password-form" [@animate]="{value: '*', params: {duration: '300ms', y: '100px'}}">
            <div class="logo">
                <img src="assets/images/logos/newlogo.png" />
            </div>

            <div class="title">RESET YOUR PASSWORD</div>

            <form name="resetPasswordForm" [formGroup]="resetPasswordForm" novalidate>
                <nz-form-item [formGroup]="resetPasswordForm">
                    <nz-form-control>
                        <input nz-input #inputElement type="password" formControlName="password" placeholder="Password" />
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item [formGroup]="resetPasswordForm">
                    <nz-form-control>
                        <input nz-input #inputElement type="password" formControlName="passwordConfirm" placeholder="Confirm Password" />
                        <div *ngIf="resetPasswordForm.get('passwordConfirm').hasError('required')">Password confirmation is required</div>
                        <div
                            *ngIf="
                                !resetPasswordForm.get('passwordConfirm').hasError('required') &&
                                resetPasswordForm.get('passwordConfirm').hasError('passwordsNotMatching')
                            ">
                            Passwords must match
                        </div>
                    </nz-form-control>
                </nz-form-item>

                <button
                    nzSize="large"
                    nzType="primary"
                    nz-button
                    class="submit-button"
                    aria-label="RESET MY PASSWORD"
                    [disabled]="resetPasswordForm.invalid"
                    (click)="resetPassword()">
                    RESET MY PASSWORD
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/login'">Go back to login</a>
            </div>
        </div>
    </div>
</div>
